import React, {useState} from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import PropTypes from 'prop-types';
import Input from "@mui/material/Input/Input";
import { enqueueSnackbar } from 'notistack'
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";

function ViewFileLinkDialog (props) {

    const [open, setOpen] = useState(true)
    const [textArea, setTextArea] = useState()
    
    const handleCloseDialog = () => {
       setOpen(false)
        //Added this to address issue of dialog not re-opening after initial open
        props.handleCloseDialog && props.handleCloseDialog()
    };

    const copyToClipboard = (e) => {
        textArea.select();
        document.execCommand('copy');
        e.target.focus();
        enqueueSnackbar("URL copied to clipboard", {variant: 'success'})
    };
    
    return (
        <div>
            <Dialog
                open={open}
                onClose={handleCloseDialog}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
                fullWidth={true}
                maxWidth="sm"
            >
                <IconButton
                    aria-label="close"
                    onClick={handleCloseDialog}
                    color={"secondary"}
                    sx={{position: 'absolute', right: 0, top: 0,}}
                >
                    <CloseIcon />
                </IconButton>
                <DialogContent sx={(theme)=>({paddingLeft: theme.spacing(4), paddingRight: theme.spacing(4), paddingTop: theme.spacing(4)})}>
                    <DialogContentText sx={(theme)=>({paddingBottom: theme.spacing(2)})}>
                        Use the URL below to access this file directly
                    </DialogContentText>
                    <br/>

                    <Input
                        id = "fileLink"
                        name = "fileLink"
                        value = {window.location.origin + "/file/" + props.boxDocID}
                        inputRef = {(textarea) => setTextArea(textarea)}
                        type = "text"
                        margin="none"
                        variant="outlined"
                        readOnly={true}
                        style={{width: "100%"}}
                        autoFocus={true}
                        onFocus={event => {event.target.select()}}
                    />

                </DialogContent>

                <DialogActions>
                    <Button onClick={copyToClipboard}
                            variant="contained"
                            color="secondary">
                        Copy to Clipboard
                    </Button>
                    <Button onClick={handleCloseDialog}
                            variant="contained"
                            color="secondary">
                        Close
                    </Button>

                </DialogActions>
            </Dialog>
        </div>
    );
}

ViewFileLinkDialog.propTypes = {
    classes: PropTypes.object,
    boxDocID: PropTypes.string.isRequired,
    handleCloseDialog: PropTypes.func.isRequired,
    handleCloseMenu: PropTypes.func
};


export default (ViewFileLinkDialog);