import React, {useEffect, useState} from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import PropTypes from 'prop-types';
import { createTheme, ThemeProvider, StyledEngineProvider } from '@mui/material/styles';
import {withStyles} from '@mui/styles';
import { enqueueSnackbar } from 'notistack'
import {DialogTitle, Grid} from "@mui/material";
import MaterialTable from"@material-table/core"
import LinearProgress from "@mui/material/LinearProgress";
import {getErrorMessageFromResponse} from "../common/helper";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";

const styles = theme => ({});
const tableTheme = theme => createTheme(({

    // to merge with parent theme
    ...theme,

    //https://material-ui.com/customization/globals/#css
    //note, overrides the overrides in the parent theme in app.css
    components: {

        MuiToolbar: {
            styleOverrides: {
                gutters: {
                    paddingLeft: theme.spacing(2)
                },
                root: {
                    paddingLeft: "0px"
                },
                regular: {
                    paddingLeft: "0px"
                }
            }
        },
        MTableToolbar: {
            styleOverrides: {
                root: {
                    paddingLeft: "0px"
                }
            }
        },
        MuiTableCell: {
            styleOverrides: {
                root: {
                    padding: '0px',
                    //paddingLeft: "24px"
                }
            }
        },

        // MuiIconButton: {
        //     styleOverrides: {
        //         root: {
        //             color: theme.palette.secondary.main,
        //         },
        //         colorInherit: {
        //             color: theme.palette.secondary.main
        //         }
        //     }
        // },

        MuiAvatar: {
            styleOverrides: {
                colorDefault: {
                    backgroundColor: theme.palette.secondary.main
                }
            }
        }
    }
}));

function AuditDialog (props)  {

    const debug = window.location.pathname.toLowerCase().includes("debug");
    const [open, setOpen] = useState(true);
    const [audits, setAudits] = useState([]);
    const [isFetching, setIsFetching] = useState(false);

    useEffect(() => {
        getAudit();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const handleCloseDialog = () => {

        //clear values
        setOpen(false);
        setAudits([])

        if (props.handleCloseDialog) {
            props.handleCloseDialog()
        }
    };

    const getAudit = async () => {

        setIsFetching(true)

        await props.triggerRefreshAuthToken();

        let pathVars = "";
        if (props.type === "case") {
            pathVars = '/case/' + props.caseId;
        } else if (props.type === "document") {
            pathVars = '/document/' + props.docId ;
        } else if (props.type === "result") {
            pathVars = '/result/' + props.resultId ;
        } else if (props.type === "snippet") {
            pathVars = '/result/' + props.resultId + '/entry/' + props.snippetId
        }

        let url = window.REACT_APP_BASE_URL_SCREENING + pathVars + "/audit"
        debug && console.log("getAudit:", url);

        let request = {
            method: 'GET',
            headers: {
                "case-token": props.userDetails.caseAccessToken,
                "Authorization": "Bearer " + props.userDetails.accessToken
            }
        };

        fetch(url , request )
            .then(response => {
                debug && console.log('getAudit RESPONSE: ', response);
                if (response.ok) {
                    return response.json();
                } else {
                    Promise.resolve(getErrorMessageFromResponse(response, 'retrieving audits'))
                        .then(message => {
                            enqueueSnackbar(message, {variant: 'error'})
                        })
                    debug && console.log("error.  url:", url, "request: ", request);
                    return null
                }
            })
            .then(result => {
                debug && console.log ('result = ', result);
                setIsFetching(false)
                let rows = [];
                if (result) {
                    result.forEach(r => {
                        r.created = new Date(r.created_at);
                        rows.push(r)
                    })
                }
                debug && console.log ('rows=', rows);
                setAudits(rows)
            })
            .catch(e => {
                setIsFetching(false)
                debug && console.log("getAudit Exception:", e, " url:", url, "request: ", request);
                enqueueSnackbar("Error retrieving audit - " + e, {variant: 'error'});
            })
    };

    const cellStyle = {fontSize: "13px", fontFamily: "roboto"}
    let cols = [
        {title: 'Date', field: 'created', type: 'datetime', cellStyle: cellStyle},
        {title: 'Name', field: 'created_by',cellStyle: cellStyle},
        {title: 'Action', field: 'action', cellStyle: cellStyle}
    ]

    return (
        <Dialog
            open={open}
            onClose={handleCloseDialog}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
            fullWidth={true}
            maxWidth="md"
        >

        <IconButton
            aria-label="close"
            onClick={handleCloseDialog}
            color={"secondary"}
            sx={{position: 'absolute', right: 0, top: 0,}}
        >
            <CloseIcon />
        </IconButton>


            <DialogTitle>History</DialogTitle>
            <DialogContent>
                <Grid container spacing={1} style={ {height: "600px"}}>
                    <Grid item xs={12} style={{height: "100%"}}>
                        <Grid container style={{height: "100%"}}>
                            <Grid item xs={12} style={{height: "100%", overflowY: "auto",}}>
                                {
                                    isFetching ?
                                        <LinearProgress variant={"indeterminate"} color={"primary"}/>  :

                                        audits && audits.length > 0 ?

                                            <StyledEngineProvider injectFirst>
                                                <ThemeProvider theme={tableTheme}>
                                                    <MaterialTable
                                                        title={""}
                                                        columns={cols}
                                                        data={audits}
                                                        options={{
                                                            pageSize: 200,
                                                            pageSizeOptions: [],
                                                            toolbar: false,
                                                            paging: false
                                                        }}
                                                        localization={{
                                                            body: {
                                                                emptyDataSourceMessage: '',
                                                                editTooltip: 'Edit'
                                                            },
                                                            toolbar: {
                                                                searchTooltip: 'Filter',
                                                                searchPlaceholder: 'Filter'
                                                            },
                                                            header: {
                                                                actions: ""
                                                            }
                                                        }}
                                                    />
                                                 </ThemeProvider>
                                             </StyledEngineProvider>
                                    :
                                            <span/>}
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </DialogContent>

            <DialogActions>
                <Button onClick={handleCloseDialog} variant="contained" color="secondary">Close</Button>
            </DialogActions>
        </Dialog>
    );

}

AuditDialog.propTypes = {
    userDetails: PropTypes.object.isRequired,
    handleCloseDialog: PropTypes.func,
    triggerRefreshAuthToken: PropTypes.func.isRequired,
    type: PropTypes.string.isRequired, //case, document, result, snippet
    caseId: PropTypes.string,
    docId: PropTypes.string,
    resultId: PropTypes.string,
    snippetId: PropTypes.string,
};

export default (withStyles(styles, { withTheme: true })(AuditDialog));