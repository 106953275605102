import { Auth } from 'aws-amplify'
import React from 'react';
import Button from '@mui/material/Button';
import Typography from "@mui/material/Typography";
import {Grid} from "@mui/material";

function LoginPage() {

    function cognitoLogin() {

        //To derive necessary data from the provider
        const {
            token, // the token you get from the provider
            //domainOrProviderName, // Either the domain of the provider(e.g. accounts.your-openid-provider.com) or the provider name, for now the library only supports 'google', 'facebook', 'amazon', 'developer'
            expiresIn, // the time in ms which describes how long the token could live
            user, // the user object you defined, e.g. { username, email, phone_number }
            identity_id, // Optional, the identity id specified by the provider
            email,
            profile
        }
            = getCognitoCredentials()           //getFromProvider(); // arbitrary function

        async function getCognitoCredentials() {
            try {
                const cred = await Auth.federatedSignIn(
                    {customProvider: 'keycloak-oidc'},
                    {
                        token,
                        identity_id, // Optional
                        expires_at: expiresIn * 1000 + new Date().getTime(), // the expiration timestamp
                        email,
                        profile
                    },
                    user
                );

            } catch (err) {
                console.log('err = ', err);
            }

        }
    }

    return (

        <Grid container spacing={0} justifyContent="center" sx={{ minHeight: '100vh', paddingTop: '150px' }}>
            <Grid item xs={3} style={{textAlign:'center'}}>
                <img src={window.location.origin + '/images/authlogo.png'} alt="logo"
                     style={{display: "block", margin: "0 auto", marginBottom: '32px', maxHeight: "80px"}}/>
                <Typography variant="h5" gutterBottom style={{paddingBottom: '32px'}}>{window.REACT_APP_APP_TITLE}</Typography>
                <Button variant='contained' onClick={() => cognitoLogin()}>
                    Sign In with Keycloak
                </Button>
            </Grid>
        </Grid>


    )
}

export default LoginPage