import React from 'react';
// import ReactDOM from 'react-dom';
import { createRoot } from 'react-dom/client';
import './index.css';
import App from './components/app/App';
import * as serviceWorker from './serviceWorker';
import { I18nextProvider } from 'react-i18next';
import i18n from './i18n'
import { UserContextProvider } from './components/auth/cognito/UserContext'
import {Auth} from 'aws-amplify'

const authenticateWith = window.REACT_APP_AUTHENTICATE_WITH;

if (authenticateWith ==='cognito') {

    console.log ('REACT_APP_COGNITO_DOMAIN=', window.REACT_APP_COGNITO_DOMAIN);
    console.log ('REACT_APP_COGNITO_REGION=', window.REACT_APP_COGNITO_REGION);
    console.log ('REACT_APP_COGNITO_USER_POOL_ID=', window.REACT_APP_COGNITO_USER_POOL_ID);
    console.log ('REACT_APP_COGNITO_USER_POOL_WEB_CLIENT_ID=', window.REACT_APP_COGNITO_USER_POOL_WEB_CLIENT_ID);

    const authConfig = {
        Auth: {
            region: window.REACT_APP_COGNITO_REGION,
            userPoolId: window.REACT_APP_COGNITO_USER_POOL_ID,
            userPoolWebClientId: window.REACT_APP_COGNITO_USER_POOL_WEB_CLIENT_ID,
            mandatorySignIn: false,
            oauth: {
                clientId: 'keycloak-oidc', //'oidc',
                domain: window.REACT_APP_COGNITO_DOMAIN,
                scope: ['openid', 'email', 'profile'], //'email, 'profile' needed too??

                redirectSignIn: window.location.origin,
                redirectSignOut: window.location.origin,
                //responseType: 'ALLOW_CUSTOM_AUTH', //code ??
                responseType: 'code',
                options: {AdvancedSecurityDataCollectionFlag: true}
            }

        }
    }
    console.log('index.js call Auth.configure with authConfig=', authConfig);
    Auth.configure(authConfig);
    console.log('index.js Auth.configure done')
}

const container = document.getElementById('root');
const root = createRoot(container);
root.render(
    <I18nextProvider i18n={i18n} >
        {
            authenticateWith === 'cognito' ?
            <UserContextProvider>
                <App tab="home" />
            </UserContextProvider> :
                <App tab="home" />
        }
    </I18nextProvider>
);
// ReactDOM.render(
//     <I18nextProvider i18n={i18n}>
//         <App />
//     </I18nextProvider>,
//     container
// );

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister();
