import React from 'react';
import MaterialTable from "@material-table/core";
import PropTypes from 'prop-types';
import {withStyles} from '@mui/styles';
import { createTheme, ThemeProvider, StyledEngineProvider } from '@mui/material/styles';
import DocumentMenu from "../../menus/DocumentMenu";
import TasksButton from "../../document/tasks/TasksButton";
import MLButton from "../../document/ml/MLButton";
import MLButtonDemo from "../../document/ml_demo/MLButtonDemo";
import PreviewButton from "../../document/preview/PreviewButton";
import Preview from "../../document/preview/Preview";
import AddDocButton from "../../document/addDocument/AddDocButton"
import { enqueueSnackbar } from 'notistack'
import RelatedDocumentsButton from "../../document/relatedDocuments/RelatedDocumentsButton";
import DeleteFile from "../../document/DeleteFile";
import MoveDocumentToCaseButton from "../../document/move/MoveDocumentToCaseButton"
import ViewFileLinkButton from "../../document/link/ViewFileLinkButton";
import ViewSharedLinkButton from "../../document/link/ViewSharedLinkButton";
import DownloadFile from "../../document/download/DownloadFile";
import CommentsButton from "../../document/comments/CommentsButton";
import DownloadResults from "../../document/download/DownloadResults";
import FilterFolderDocuments from "./FilterFolderDocuments";
import FilterFolderDocumentsByMetadata from "./FilterFolderDocumentsByMetadata";
import FolderDocumentsRefresh from "./FolderDocumentsRefresh";
import {Paper} from "@mui/material";
import SearchCriteriaTooltip from "../../search/SearchCriteriaTooltip";
import ColumnIcon from "../../search/results/ColumnIcon";
import {
    getErrorMessageFromResponse,
    getFieldConfig,
    getObjectByKey,
    hasAccess,
    isNumeric,
    numberWithCommas,
    validateDate
} from "../../common/helper"
import Tooltip from "@mui/material/Tooltip/Tooltip";
import Button from "@mui/material/Button";
import CircularProgress from "@mui/material/CircularProgress";
import Typography from "@mui/material/Typography";
import ActionButtonsDocument from "../../document/workflow/ActionButtonsDocument";
import {dynamicSort} from "../../search/helper";
import {ContentOpenWith} from "box-ui-elements";
import CompareParentButton from "../../document/compare/CompareParentButton";
import ComparePreviousButton from "../../document/compare/ComparePreviousButton";
import CompareCustomButton from "../../document/compare/CompareCustomButton";
import AuditButton from "../../audit/AuditButton";
import DisplayOptionButtons from "../../search/results/DisplayOptionButtons";
import CustomGroupRow from "../../common/customMaterialTable/CustomGroupRow";
import PreviewDialog from "../../document/preview/PreviewDialog";
import {withTranslation} from 'react-i18next'
import InfiniteScrollLoader from "../../search/results/InfiniteScrollLoader";
import InfiniteScrollEndMessage from "../../search/results/InfiniteScrollEndMessage";
import InfiniteScroll from 'react-infinite-scroll-component';
import SignRequestButton from "../../document/sign/SignRequestButton";
import FileIcon from "../../common/FileIcon";
import FileIconThumbnail from "../../common/FileIconThumbnail";
import SubfolderGroupMembersButton from "../subfolders/security/SubfolderGroupMembersButton";
import EditComponentMaterialTable from "../../common/EditComponentMaterialTable";
import {checkHasMultiSelectActionsFolderDocs, downloadFileMulti} from "../../document/multiSelectActions";
import DownloadFilenameDialog from "../../document/download/DownloadFilenameDialog"
import { ExportCsv, ExportPdf } from '@material-table/exporters';
import { ReactComponent as ExportIcon } from '../../common/images/export.svg';
import idLocale from 'date-fns/locale/id';


const styles = theme => ({});
const tableTheme = theme => createTheme(({

    // to merge with parent theme
    ...theme,
    components: {
        MuiOutlinedInput: {
            styleOverrides: {
                notchedOutline :{
                    borderStyle: 'none',
                    borderBottom: `1px solid #000`
                },
                root: {
                    paddingLeft: '0',
                    paddingBottom: theme.spacing(0.5),
                    borderRadius: '0'
                },
                input: {
                    paddingLeft: '0',
                    paddingRight: '0',
                    paddingTop: '0',
                    paddingBottom: '0'
                }
            }
        }
    }

}));

class SearchResultsDocumentsFolderDocsTable extends React.Component {

    constructor(props) {

        super(props);

        //window.location.pathname.toLowerCase().includes("debug") && console.log('SearchResultsDocumentsFolderDocsTable props = ' , props);

        this.updateIsProcessing = this.updateIsProcessing.bind(this);
        this.closeDownloadFilenameDialog = this.closeDownloadFilenameDialog.bind(this);


        const searchConfig = props.selectedWorkspaceConfig.searchConfig.folderDocuments;

        //Add a column for each column in workspaceConfig
        let columns = [];
        let hasEditableColumns = false;
        let enableGrouping = false;
        let enableColumnFiltering = false;
        let fixedColumnsLeft = 0
        let fixedColumnsRight = 0

        const tableConfig = searchConfig && searchConfig.resultsDisplay && searchConfig.resultsDisplay.table;
        const showFileIcon = tableConfig && tableConfig.showFileIcon;
        const showThumbnail = tableConfig && tableConfig.showThumbnail;
        const showDeleteAction =  props.actionsConfig.delete.enabled && hasAccess(props.actionsConfig.delete, props.userDetails.userRoles)

        const fileUrlPropertyOptions = props.actionsConfig.previewDocument.pdfViewer && props.actionsConfig.previewDocument.pdfViewer.fileUrlPropertyOptions ?
            props.actionsConfig.previewDocument.pdfViewer.fileUrlPropertyOptions : [];

        // const fileUrlPropertyOptionsDownload = props.actionsConfig.downloadFile && props.actionsConfig.downloadFile.preSignedUrl && props.actionsConfig.downloadFile.preSignedUrl.fileUrlPropertyOptions ?
        //     props.actionsConfig.downloadFile.preSignedUrl.fileUrlPropertyOptions : [];

        if (showFileIcon) {
            fixedColumnsLeft++
            columns.push({
                label: "",
                field: 'name',
                filtering: false,
                sorting: false,
                cellStyle: {
                    padding: 0,
                    textAlign: "center",
                    // maxWidth: 30,
                    width: 30,
                    paddingRight: '8px'
                },
                headerStyle: {
                    textAlign: "center",
                    //maxWidth: 30,
                    width: 30,
                },
                editable: "never",
                grouping: false,
                export: false,
                editComponent: rowData => {return (<span/>)}, //even though editable set to 'never' this is required to prevent editable field from appearing for icons
                render: rowData => {
                    return (
                        <React.Fragment>
                            {
                                showThumbnail ?
                                    <FileIconThumbnail height={'24px'} width={'24px'} file={rowData} userDetails={this.props.userDetails} triggerRefreshAuthToken={this.props.triggerRefreshAuthToken}/> :
                                    <Tooltip title={rowData.name}><div><FileIcon height={'24px'} width={'24px'} fileName={rowData.name}/></div></Tooltip>
                            }
                        </React.Fragment>
                    )
                }
            });
        }

        searchConfig.resultsColumns.forEach((column) => {

            if (!column.hide) {

                let fieldConfig = getFieldConfig(props.metadataConfig, column.templateKey, column.metadataKey);

                if (column.displayAsIcon) {

                    fixedColumnsRight++

                    let iconConfig = fieldConfig.iconConfig;
                    if (!iconConfig) {
                        window.location.pathname.toLowerCase().includes("debug") && console.log('iconConfig not available for ' + column.templateKey + "-" + column.metadataKey);
                    }

                    columns.push({
                        title: "",
                        field: column.templateKey + "~" + column.metadataKey,
                        metadataKey: column.metadataKey,
                        templateKey: column.templateKey,
                        filtering: false,
                        sorting: false,
                        width: 16,
                        cellStyle: {
                            // maxWidth: 10,
                            width: 16,
                            padding: 0,
                            paddingRight: 8,
                            textAlign: "center",
                            // fontSize: "13px"
                            // marginRight: 40
                        },
                        headerStyle: {
                            textAlign: "center",
                            //maxWidth: 10,
                            width: 16,
                        },
                        editable: "never",
                        grouping: false,
                        export: false,
                        editComponent: rowData => {return (<span/>)}, //even though editable set to 'never' this is required to prevent editable field from appearing for icons
                        render: rowData => {
                            return (
                                <ColumnIcon val={rowData[column.metadataKey]} iconConfig={iconConfig}/>
                            )
                        }
                    });

                } else {

                    let col = JSON.parse(JSON.stringify(column)); //deep clone
                    col.editable = col.editable ? "always" : "never";
                    //width required for resizable columns
                    //NB need at least one column with no width set, otherwise the detailPanel icon and action icons are resized
                    //col.width = column.width ? column.width : column.type ==='date' ? '200px' : '150px' //date columns need to be wider to allow for clear icon in edit mode
                    //col.width = column.width ? column.width : column.type ==='date' && '170px' //only set width if it is specified, or if an editable date column
                    //col.width = column.width ? column.width : (column.editable && column.type ==='date') && '250px !important' //only set width if it is specified, or if an editable date column

                    //Note - removing line above to set column width was needed to address issues encountered with tableLayout=fixed

                    col.resizable = column.resizable === true //only make resizable if specified in config


                    if (!col.cellStyle) {
                        col.cellStyle = {}
                    }
                    col.cellStyle.fontSize =  "13px"
                    col.cellStyle.fontFamily =  "roboto"

                    if (col.groupOrderConditional) {
                        //override defaultGroupOrder with value from folder
                        if (props.folderDetails[col.groupOrderConditional.templateKey + "~" + col.groupOrderConditional.metadataKey]) {
                            let val = props.folderDetails[col.groupOrderConditional.templateKey + "~" + col.groupOrderConditional.metadataKey]
                            if (val) {
                                col.defaultGroupOrder = val
                            }
                        }
                    }

                    const isGroupingCol = !!(col.defaultGroupOrder && col.defaultGroupOrder > 0);
                    // col.cellStyle.paddingRight = ((col.type === "numeric" && (!col.defaultGroupOrder || col.defaultGroupOrder === 0)) || col.editable === 'always') ? "16px" : "0px"
                    col.cellStyle.paddingRight = isGroupingCol ? "0px" : (col.type === "numeric" && col.editable === 'always') ? "16px" : "8px"
                    col.cellStyle.paddingLeft = "0px"

                    col.filterCellStyle = {
                        paddingLeft: "0px"
                        // paddingRight: "8px"
                    }

                    //check config for any custom column styling
                    let headerStyle = col.headerStyle ? col.headerStyle : {};
                    headerStyle.paddingRight = (col.type === "numeric" && (!col.defaultGroupOrder  || col.defaultGroupOrder === 0)) ? "16px" : "0px"
                    col.headerStyle = headerStyle;

                    col.title = col.label;
                    col.field = col.templateKey === "n/a" ? col.metadataKey : col.templateKey + "~" + col.metadataKey;

                    if(col.defaultGroupOrder === 0) {
                        delete col.defaultGroupOrder
                    }

                    //grouping
                    if (!col.defaultGroupOrder ) {
                        col.grouping = false
                        if (col.groupOrderConditional) {
                            //if conditional grouping criteria not met then hide the column
                            col.hidden = true
                        }
                    } else {
                        enableGrouping = true //enable grouping for table if defaultGroupOrder set for any columns
                    }

                    //column filtering - set to true if any columns have filtering enabled
                    if (col.filtering) {
                        enableColumnFiltering = true
                        // col.customFilterAndSearch = (term, rowData) => term === "" ? null : term
                        if (col.type === 'date') {
                            //disable column filtering for dates due to issues with date format in filter
                            col.filtering = false
                       }
                    } else {
                        col.filtering = false //set to false if the property doesn't exist
                    }

                    col.editComponent = props => {
                        const value = props.value;
                        return (
                            <EditComponentMaterialTable
                                onChange={props.onChange} col={col} fieldConfig={fieldConfig} value={value} rowData={props.rowData}
                                metadataConfig={this.props.metadataConfig} optionsConfig={this.props.optionsConfig}/>
                        )
                    }

                    //to cater for IQEQ documentPeriod, where the month values and label are different
                    if (col.defaultGroupOrder && col.defaultGroupOrder > 0 && fieldConfig.optionsDependencies && fieldConfig.optionsDependencies.length > 0) {
                        col.optionsDependencies = fieldConfig.optionsDependencies;
                    }

                    columns.push(col)

                    if (column.editable) {
                        hasEditableColumns = true;
                    }

                }
            }

        });  //end resultsColumns

        //Actions columns - don't add for Sign requests

        if(!this.props.sign) {

            //append a column for ContentOpenWith component
            //if action is enabled and user role listed in config then add a column action
            if (!this.props.sign && props.actionsConfig.openWithFromTable && props.actionsConfig.openWithFromTable.enabled) {
                if (hasAccess(props.actionsConfig.openWithFromTable, props.userDetails.userRoles)) {

                    fixedColumnsRight++

                    columns.push({
                        title: '',
                        field: 'id',
                        filtering: false,
                        sorting: false,
                        width: 110,
                        cellStyle: {
                            width: 110,
                            //maxWidth: 110,
                            padding: 0,
                            paddingLeft: "8px"
                            // marginRight: 40
                        },
                        headerStyle: {
                            width: 110,
                            //maxWidth: 110,
                            padding: 0,
                        },
                        disableClick: true,
                        export: false,
                        editComponent: rowData => {
                            return (<span/>)
                        },
                        render: rowData => {

                            const usePreviewFor = props.actionsConfig.openWithFromTable.usePreviewFor;
                            const fileType = rowData.name.substring(rowData.name.lastIndexOf("."));
                            let usePreview = false;
                            if (usePreviewFor && usePreviewFor.length > 0) {
                                if (usePreviewFor.indexOf(fileType) > -1) {
                                    usePreview = true
                                }
                            }

                            const usePreviewWhen = props.actionsConfig.openWithFromTable.usePreviewWhen;

                            if (usePreviewWhen && Object.entries(usePreviewWhen).length > 0) {
                                if (rowData[usePreviewWhen.templateKey + "~" + usePreviewWhen.metadataKey] && usePreviewWhen.values.indexOf(rowData[usePreviewWhen.templateKey + "~" + usePreviewWhen.metadataKey]) !== -1) {
                                    usePreview = true
                                }
                            }

                            let hide = false;
                            const hideWhen = props.actionsConfig.openWithFromTable.hideWhen;
                            if (hideWhen && Object.entries(hideWhen).length > 0) {
                                if (rowData[hideWhen.templateKey + "~" + hideWhen.metadataKey] && (rowData[hideWhen.templateKey + "~" + hideWhen.metadataKey] === hideWhen.value)) {
                                    hide = true;
                                }
                            }

                            return (
                                hide ?
                                    <span/> :
                                    usePreview ?
                                        <PreviewButton
                                            parentClasses={this.props.classes}
                                            boxDocID={rowData.id}
                                            userDetails={this.props.userDetails}
                                            metadataConfig={this.props.metadataConfig}
                                            optionsConfig={this.props.optionsConfig}
                                            workspaceConfig={this.props.workspaceConfig}
                                            selectedWorkspaceConfig={this.props.selectedWorkspaceConfig}
                                            actionsConfig={this.props.actionsConfig}
                                            triggerRefreshAuthToken={this.props.triggerRefreshAuthToken}
                                            unmountFolderDocumentsGet={this.props.unmountFolderDocumentsGet}
                                            documentDetails={rowData}
                                            documentMetadataFields={searchConfig && searchConfig.documentMetadataFields}
                                        /> :
                                        <ContentOpenWith
                                            fileId={rowData.id}
                                            token={this.props.userDetails.boxAccessToken}
                                        />
                            )
                        },
                    })
                }
            }

            //append a column for PreviewDocumentWithProperties component
            //if action is enabled and user role listed in config then add a column action
            if (props.actionsConfig.previewDocument.enabled &&
                hasAccess(props.actionsConfig.previewDocument, props.userDetails.userRoles)) {
                    fixedColumnsRight++
                    columns.push({
                        title: '',
                        field: 'id',
                        filtering: false,
                        sorting: false,
                        width: 32,
                        cellStyle: {
                            width: 32,
                            //maxWidth: 48,
                            padding: 0,
                            // marginRight: 40
                        },
                        headerStyle: {
                            width: 32,
                            //maxWidth: 48,
                            padding: 0,
                            // marginRight: 40
                        },
                        disableClick: true,
                        export: false,
                        editComponent: rowData => {
                            return (<span/>)
                        },
                        render: rowData => {
                            return (
                                <PreviewButton
                                    parentClasses={this.props.classes}
                                    boxDocID={rowData.id}
                                    userDetails={this.props.userDetails}
                                    metadataConfig={this.props.metadataConfig}
                                    optionsConfig={this.props.optionsConfig}
                                    workspaceConfig={this.props.workspaceConfig}
                                    selectedWorkspaceConfig={this.props.selectedWorkspaceConfig}
                                    actionsConfig={this.props.actionsConfig}
                                    triggerRefreshAuthToken={this.props.triggerRefreshAuthToken}
                                    unmountFolderDocumentsGet={this.props.unmountFolderDocumentsGet}
                                    documentDetails={rowData}
                                    documentMetadataFields={searchConfig && searchConfig.documentMetadataFields}
                                />
                            )
                        },
                    })
            }

            //File Workflow actions
            if (props.workflowConfig && props.workflowConfig.length > 0) {
                // Build list of enabled file workflow actions that the user has access to
                let enabledActions = [];
                for (let i = 0; i < props.workflowConfig.length; i++) {
                    if (props.workflowConfig[i].enabled && hasAccess(props.workflowConfig[i], props.userDetails.userRoles)) {
                        enabledActions.push(
                            props.workflowConfig[i]
                        )
                    }
                }
                //If user has access to any enabled workflow actions then add a single column to display all the available actions
                if (enabledActions.length > 0) {
                    fixedColumnsRight++
                    columns.push({
                        title: 'Workflow',
                        field: 'id',
                        filtering: false,
                        sorting: false,
                        disableClick: true,
                        export: false,
                        editComponent: rowData => {
                            return (<span/>)
                        },
                        render: rowData => {
                            return(
                                <ActionButtonsDocument
                                    documentDetails={rowData}
                                    actions={enabledActions}
                                    folderId={this.props.folderDetails.id}
                                    userDetails={this.props.userDetails}
                                    triggerRefreshAuthToken={this.props.triggerRefreshAuthToken}
                                    unmountSearchResults={props.unmountFolderDocumentsGet}
                                    metadataConfig={props.metadataConfig}
                                    updateFolderDetails={props.updateFolderDetails}
                                    unmountFolderDocumentsGet={props.unmountFolderDocumentsGet}
                                />
                            )
                        },
                    });
                }
            }

            //append a column for Download File component
            //if action is enabled and user role listed in config then add a column action
            if (props.actionsConfig.downloadFile.enabled) {
                if (hasAccess(props.actionsConfig.downloadFile, props.userDetails.userRoles)) {
                    fixedColumnsRight++
                    columns.push({
                        title: '',
                        field: 'id',
                        filtering: false,
                        sorting: false,
                        width: 32,
                        cellStyle: {
                            //width: 48,
                            //maxWidth: 48,
                            padding: 0,
                            // marginRight: 40
                        },
                        headerStyle: {
                            width: 32,
                            //maxWidth: 48,
                            padding: 0,
                            // marginRight: 40
                        },
                        disableClick: true,
                        export: false,
                        editComponent: rowData => {
                            return (<span/>)
                        },
                        render: rowData => {

                            //get fileUrl field if available
                            let fileUrl="";
                            for (let i=0; i<fileUrlPropertyOptions.length; i++) {
                                if (rowData[fileUrlPropertyOptions[i]]) {
                                    fileUrl = rowData[fileUrlPropertyOptions[i]];
                                    break;
                                }
                            }

                            return (
                                <DownloadFile
                                    boxDocID={rowData.id}
                                    fileName={rowData.name ? rowData.name : rowData.original_name}
                                    userDetails={this.props.userDetails}
                                    downloadFileActionConfig={this.props.actionsConfig.downloadFile}
                                    triggerRefreshAuthToken={this.props.triggerRefreshAuthToken}
                                    fileUrl={fileUrl}
                                />
                            )
                        },
                    })
                }
            }

            //append a column for Download File component
            //if action is enabled and user role listed in config then add a column action
            if (props.actionsConfig.viewLinkFile.enabled) {
                if (hasAccess(props.actionsConfig.viewLinkFile, props.userDetails.userRoles)) {
                    fixedColumnsRight++
                    columns.push({
                        title: '',
                        field: 'id',
                        filtering: false,
                        sorting: false,
                        width: 32,
                        cellStyle: {
                            width: 32,
                            //maxWidth: 48,
                            padding: 0,
                            // marginRight: 40
                        },
                        headerStyle: {
                            width: 32,
                            //maxWidth: 48,
                            padding: 0,
                            // marginRight: 40
                        },
                        disableClick: true,
                        export: false,
                        editComponent: rowData => {
                            return (<span/>)
                        },
                        render: rowData => {
                            return (
                                <ViewFileLinkButton
                                    boxDocID={rowData.id}
                                    actionConfig={this.props.actionsConfig.viewLinkFile}
                                />
                            )
                        },
                    })
                }
            }

            //append a column for compareParent button component
            //if action is enabled and user role listed in config then add a column action
            if (props.actionsConfig.compareParent && props.actionsConfig.compareParent.enabled) {
                if (hasAccess(props.actionsConfig.compareParent, props.userDetails.userRoles)) {
                    fixedColumnsRight++
                    columns.push({
                        title: '',
                        field: 'id',
                        filtering: false,
                        sorting: false,
                        width: 32,
                        cellStyle: {
                            width: 32,
                            //maxWidth: 48,
                            padding: 0,
                            // marginRight: 40
                        },
                        headerStyle: {
                            width: 32,
                            //maxWidth: 48,
                            padding: 0,
                            // marginRight: 40
                        },
                        disableClick: true,
                        export: false,
                        editComponent: rowData => {
                            return (<span/>)
                        },
                        render: rowData => {
                            const actionConfig = props.actionsConfig.compareParent;
                            return (
                                rowData[actionConfig.compareToIdMetadataKey] && rowData[actionConfig.compareToIdMetadataKey] !== "" ?
                                    <CompareParentButton
                                        parentClasses={this.props.classes}
                                        id={rowData.id}
                                        parentFileId={rowData[actionConfig.compareToIdMetadataKey]}
                                        actionConfig={actionConfig}
                                        userDetails={this.props.userDetails}
                                        triggerRefreshAuthToken={this.props.triggerRefreshAuthToken}/> :
                                    <span/>
                            )
                        },
                    })
                }
            }

            //append a column for comparePrevious button component
            //if action is enabled and user role listed in config then add a column action
            if (props.actionsConfig.comparePrevious && props.actionsConfig.comparePrevious.enabled) {
                if (hasAccess(props.actionsConfig.comparePrevious, props.userDetails.userRoles)) {
                    fixedColumnsRight++
                    columns.push({
                        title: '',
                        field: 'id',
                        filtering: false,
                        sorting: false,
                        width: 32,
                        cellStyle: {
                            width: 32,
                            //maxWidth: 48,
                            padding: 0,
                            // marginRight: 40
                        },
                        headerStyle: {
                            width: 32,
                            //maxWidth: 48,
                            padding: 0,
                            // marginRight: 40
                        },
                        disableClick: true,
                        export: false,
                        editComponent: rowData => {
                            return (<span/>)
                        },
                        render: rowData => {

                            const displayWhen = props.actionsConfig.comparePrevious.displayWhen;
                            let display;
                            if (displayWhen) {
                                if (Object.entries(displayWhen).length === 0) {
                                    display = true;
                                } else {
                                    display = rowData[displayWhen.metadataKey] === displayWhen.value;
                                }
                            } else {
                                display = true
                            }

                            const displayAlways = props.actionsConfig.comparePrevious.displayAlways;
                            return (
                                (displayAlways || (display && rowData.sequence_id && rowData.sequence_id !== "" && parseInt(rowData.sequence_id) > 1)) ?
                                    <ComparePreviousButton
                                        parentClasses={this.props.classes}
                                        id={rowData.id}
                                        actionConfig={this.props.actionsConfig.comparePrevious}
                                        userDetails={this.props.userDetails}
                                        triggerRefreshAuthToken={this.props.triggerRefreshAuthToken}/> :
                                    <span/>
                            )
                        },
                    })
                }
            }

            //append a column for compareCustom button component
            //if action is enabled and user role listed in config then add a column action
            if (props.actionsConfig.compareCustom && props.actionsConfig.compareCustom.enabled) {
                if (hasAccess(props.actionsConfig.compareCustom, props.userDetails.userRoles)) {
                    fixedColumnsRight++
                    columns.push({
                        title: '',
                        field: 'id',
                        filtering: false,
                        sorting: false,
                        width: 32,
                        cellStyle: {
                            width: 32, 
                            //maxWidth: 48, 
                            padding: 0},
                        headerStyle: {
                            width: 32, 
                            //maxWidth: 48, 
                            padding: 0},
                        disableClick: true,
                        export: false,

                        editComponent: rowData => {
                            return (<span/>)
                        },
                        render: rowData => {
                            const displayWhen = props.actionsConfig.compareCustom.displayWhen;
                            let display;
                            if (displayWhen) {
                                if (Object.entries(displayWhen).length === 0) {
                                    //no criteria specified so display
                                    display = true;
                                } else {
                                    display = rowData[displayWhen.metadataKey] === displayWhen.value;
                                }
                            } else {
                                display = true
                            }

                            return (
                                display ?
                                    <CompareCustomButton
                                        parentClasses={this.props.classes}
                                        id={rowData.id}
                                        parentFileId={rowData.parentFileId}
                                        actionConfig={this.props.actionsConfig.compareCustom}
                                        userDetails={this.props.userDetails}
                                        triggerRefreshAuthToken={this.props.triggerRefreshAuthToken}/> :
                                    <span/>
                            )
                        },
                    })
                }
            }

            //append a column for Shared Link button component
            //if action is enabled and user role listed in config then add a column action
            if (props.actionsConfig.viewSharedLinkFile && props.actionsConfig.viewSharedLinkFile.enabled) {
                if (hasAccess(props.actionsConfig.viewSharedLinkFile, props.userDetails.userRoles)) {
                    fixedColumnsRight++
                    columns.push({
                        title: '',
                        field: 'id',
                        filtering: false,
                        sorting: false,
                        width: 32,
                        cellStyle: {
                            width: 32,
                            //maxWidth: 48,
                            padding: 0,
                            // marginRight: 40
                        },
                        headerStyle: {
                            width: 32,
                            //maxWidth: 48,
                            padding: 0,
                            // marginRight: 40
                        },
                        disableClick: true,
                        export: false,
                        editComponent: rowData => {
                            return (<span/>)
                        },
                        render: rowData => {
                            return (
                                rowData.shared_link && rowData.shared_link !== "" ?
                                    <ViewSharedLinkButton
                                        sharedLink={rowData.shared_link}
                                        actionConfig={this.props.actionsConfig.viewSharedLinkFile}/> :
                                    <span/>)

                        },
                    })
                }
            }

            //append a column for Comments Button component
            //if action is enabled and user role listed in config then add a column action
            if (props.actionsConfig.comments.enabled) {
                if (hasAccess(props.actionsConfig.comments, props.userDetails.userRoles)) {
                    fixedColumnsRight++
                    columns.push({
                        title: '',
                        field: 'id',
                        filtering: false,
                        sorting: false,
                        width: 32,
                        cellStyle: {
                            width: 32,
                            //maxWidth: 48,
                            padding: 0,
                            // marginRight: 40
                        },
                        headerStyle: {
                            width: 32,
                            //maxWidth: 48,
                            padding: 0,
                            // marginRight: 40
                        },
                        disableClick: true,
                        export: false,
                        editComponent: rowData => {
                            return (<span/>)
                        },
                        render: rowData => {
                            let comments = [];
                            if (window.REACT_APP_DOCUMENT_SOURCE === "elastic" && window.REACT_APP_COMMENT_SOURCE === "elastic") {
                                if (rowData.metadata && rowData.metadata.comments) {
                                    comments = rowData.metadata.comments;
                                }
                            }

                            //get fileUrl field if available
                            let fileUrl="";
                            for (let i=0; i<fileUrlPropertyOptions.length; i++) {
                                if (rowData[fileUrlPropertyOptions[i]]) {
                                    fileUrl = rowData[fileUrlPropertyOptions[i]];
                                    break;
                                }
                            }

                            return (
                                <CommentsButton
                                    boxDocID={rowData.id}
                                    fileUrl={fileUrl}
                                    userDetails={this.props.userDetails}
                                    actionsConfig={this.props.actionsConfig}
                                    triggerRefreshAuthToken={this.props.triggerRefreshAuthToken}
                                    comments={comments}
                                    unmountSearchResults={props.unmountFolderDocumentsGet}
                                    documentDetails={rowData}
                                />
                            )
                        },
                    });
                }
            }

            if (props.actionsConfig.watson.enabled) {
                if (hasAccess(props.actionsConfig.watson, props.userDetails.userRoles)) {

                    //create a json with watson results so that we can use them
                    //append a column for Watson component
                    fixedColumnsRight++
                    columns.push({
                            title: '',
                            field: 'id',
                            filtering: false,
                            sorting: false,
                            width: 32,
                            cellStyle: {
                                width: 32,
                                //maxWidth: 48,
                                padding: 0,
                                // marginRight: 40
                            },
                            headerStyle: {
                                width: 32,
                                //maxWidth: 48,
                                padding: 0,
                                // marginRight: 40
                            },
                            disableClick: true,
                            export: false,
                            editComponent: rowData => {
                                return (<span/>)
                            },
                            render: rowData => {
                                const actionConfig = props.actionsConfig.watson;

                                //get fileUrl field if available
                                let fileUrl="";
                                for (let i=0; i<fileUrlPropertyOptions.length; i++) {
                                    if (rowData[fileUrlPropertyOptions[i]]) {
                                        fileUrl = rowData[fileUrlPropertyOptions[i]];
                                        break;
                                    }
                                }

                                if (window.REACT_APP_ENV === "dev" && actionConfig.useDemoVersion) {

                                    const displayWhen = actionConfig.displayWhen;
                                    let display;
                                    if (displayWhen) {
                                        if (Object.entries(displayWhen).length === 0) {
                                            display = true;
                                        } else {
                                            display = rowData[displayWhen.metadataKey] === displayWhen.value;
                                        }
                                    } else {
                                        display = true
                                    }

                                    return (
                                        display ?
                                            <MLButtonDemo
                                                boxDocID={rowData.id}
                                                userDetails={this.props.userDetails}
                                                metadataConfig={this.props.metadataConfig}
                                                uploadConfig={this.props.uploadConfig}
                                                actionsConfig={this.props.actionsConfig}
                                                workspaceConfig={this.props.workspaceConfig}
                                                selectedWorkspaceConfig={this.props.selectedWorkspaceConfig}
                                                triggerRefreshAuthToken={this.props.triggerRefreshAuthToken}
                                                unmountFolderDocumentsGet={this.props.unmountFolderDocumentsGet}
                                                folderDetails={this.props.folderDetails}
                                                documentDetails={rowData}
                                                updateFolderDetails={props.updateFolderDetails}
                                            /> :
                                            <span/>
                                    )
                                } else {
                                    return (
                                        <MLButton
                                            boxDocID={rowData.id}
                                            fileUrl={fileUrl}
                                            userDetails={this.props.userDetails}
                                            metadataConfig={this.props.metadataConfig}
                                            uploadConfig={this.props.uploadConfig}
                                            actionsConfig={this.props.actionsConfig}
                                            workspaceConfig={this.props.workspaceConfig}
                                            selectedWorkspaceConfig={this.props.selectedWorkspaceConfig}
                                            triggerRefreshAuthToken={this.props.triggerRefreshAuthToken}
                                            unmountFolderDocumentsGet={this.props.unmountFolderDocumentsGet}
                                            folderDetails={this.props.folderDetails}
                                            documentDetails={rowData}
                                            updateFolderDetails={props.updateFolderDetails}
                                        />
                                    )
                                }
                            },
                        }
                    );
                }
            }

            if (props.actionsConfig.tasks.enabled) {
                if (hasAccess(props.actionsConfig.tasks, props.userDetails.userRoles)) {

                    //append a column for TasksButton component
                    fixedColumnsRight++
                    columns.push({
                        title: '',
                        field: 'id',
                        filtering: false,
                        sorting: false,
                        width: 32,
                        cellStyle: {
                            width: 32,
                            //maxWidth: 48,
                            padding: 0,
                            // marginRight: 40
                        },
                        headerStyle: {
                            width: 32,
                            //maxWidth: 48,
                            padding: 0,
                            // marginRight: 40
                        },
                        disableClick: true,
                        export: false,
                        editComponent: rowData => {
                            return (<span/>)
                        },
                        render: rowData => {
                            if (window.REACT_APP_APP_TITLE !== "Pearson Education Portal" || rowData["pearson~documentType"] === "Assignment") {
                                return (
                                    <TasksButton
                                        parentClasses={this.props.classes}
                                        boxDocID={rowData.id}
                                        userDetails={this.props.userDetails}
                                        metadataConfig={this.props.metadataConfig}
                                        uploadConfig={this.props.uploadConfig}
                                        actionsConfig={this.props.actionsConfig}
                                        workspaceConfig={this.props.workspaceConfig}
                                        selectedWorkspaceConfig={this.props.selectedWorkspaceConfig}
                                        triggerRefreshAuthToken={this.props.triggerRefreshAuthToken}
                                    />

                                )
                            }
                        },
                    });
                }
            }

            if (props.actionsConfig.signRequest && props.actionsConfig.signRequest.enabled) {
                if (hasAccess(props.actionsConfig.signRequest, props.userDetails.userRoles)) {
                    fixedColumnsRight++
                    columns.push({
                        title: '',
                        field: 'id',
                        filtering: false,
                        sorting: false,
                        width: 32,
                        cellStyle: {
                            width: 32,
                            //maxWidth: 48,
                            padding: 0,
                            // marginRight: 40
                        },
                        headerStyle: {
                            width: 32,
                            //maxWidth: 48,
                            padding: 0,
                            // marginRight: 40
                        },
                        disableClick: true,
                        export: false,
                        editComponent: rowData => {
                            return (<span/>)
                        },
                        render: rowData => {
                            //if (rowData.objectType === "document") {
                            const displayWhen = props.actionsConfig.signRequest && props.actionsConfig.signRequest.displayWhen;
                            let display;
                            if (displayWhen) {
                                if (Object.entries(displayWhen).length === 0) {
                                    display = true;
                                } else {
                                    display = rowData[displayWhen.metadataKey] === displayWhen.value;
                                }
                            } else {
                                display = true
                            }
                            return (
                                display ?
                                    <SignRequestButton
                                        parentClasses={this.props.classes}
                                        boxDocID={rowData.id}
                                        fileName={rowData.name}
                                        userDetails={this.props.userDetails}
                                        triggerRefreshAuthToken={this.props.triggerRefreshAuthToken}
                                        unmountFolderDocumentsGet={props.unmountFolderDocumentsGet}
                                        folderDetails={this.props.folderDetails}
                                        documentDetails={rowData}
                                        actionConfig={props.actionsConfig.signRequest}
                                    /> :
                                    <span/>
                            )
                            //}
                        },
                    });
                }
            }

            //append a column for Related Documents component
            if (props.actionsConfig.relatedDocuments.enabled) {
                if (hasAccess(props.actionsConfig.relatedDocuments, props.userDetails.userRoles)) {
                    fixedColumnsRight++
                    columns.push({
                        title: '',
                        field: 'id',
                        filtering: false,
                        sorting: false,
                        width: 32,
                        cellStyle: {
                            width: 32,
                            //maxWidth: 48,
                            padding: 0,
                            // marginRight: 40
                        },
                        headerStyle: {
                            width: 32,
                            //maxWidth: 48,
                            padding: 0,
                            // marginRight: 40
                        },
                        disableClick: true,
                        export: false,
                        editComponent: rowData => {
                            return (<span/>)
                        },
                        render: rowData => {
                            return (
                                <RelatedDocumentsButton
                                    userDetails={this.props.userDetails}
                                    actionsConfig={this.props.actionsConfig}
                                    searchConfig={this.props.selectedWorkspaceConfig.searchConfig.folderDocuments}
                                    metadataConfig={this.props.metadataConfig}
                                    folderDetails={this.props.folderDetails}
                                    documentDetails={rowData}
                                    triggerRefreshAuthToken={this.props.triggerRefreshAuthToken}
                                />
                            )
                        },
                    })
                }
            }

            //append a column for Move Document to Case
            if (props.actionsConfig.moveDocumentToCase && props.actionsConfig.moveDocumentToCase.enabled) {
                if (hasAccess(props.actionsConfig.moveDocumentToCase, props.userDetails.userRoles)) {
                    fixedColumnsRight++
                    columns.push({
                        title: '',
                        field: 'id',
                        filtering: false,
                        sorting: false,
                        width: 32,
                        cellStyle: {
                            width: 32,
                            //maxWidth: 48,
                            padding: 0,
                            // marginRight: 40
                        },
                        headerStyle: {
                            width: 32,
                            //maxWidth: 48,
                            padding: 0,
                            // marginRight: 40
                        },
                        disableClick: true,
                        export: false,
                        editComponent: rowData => {
                            return (<span/>)
                        },
                        render: rowData => {
                            return (
                                <MoveDocumentToCaseButton
                                    boxDocID={rowData.id}
                                    rowData={rowData}
                                    folderDetails={this.props.folderDetails}
                                    userDetails={this.props.userDetails}
                                    actionConfig={this.props.actionsConfig.moveDocumentToCase}
                                    triggerRefreshAuthToken={this.props.triggerRefreshAuthToken}
                                    unmountFolderDocumentsGet={this.props.unmountFolderDocumentsGet}
                                />
                            )
                        },
                    })
                }
            }

            //append a column for Delete
            if (showDeleteAction) {
                fixedColumnsRight++
                columns.push({
                        title: '',
                        field: 'id',
                        filtering: false,
                        sorting: false,
                        width: 32,
                        cellStyle: {
                            width: 32,
                            //maxWidth: 48,
                            padding: 0,
                            // marginRight: 40
                        },
                        headerStyle: {
                            width: 32,
                            //maxWidth: 48,
                            padding: 0,
                            // marginRight: 40
                        },
                        disableClick: true,
                        export: false,
                        editComponent: rowData => {
                            return (<span/>)
                        },
                        render: rowData => {
                            return (
                                <DeleteFile
                                    boxDocID={rowData.id}
                                    userDetails={this.props.userDetails}
                                    triggerRefreshAuthToken={this.props.triggerRefreshAuthToken}
                                    actionConfig={props.actionsConfig.delete}
                                    fileName={rowData.name ? rowData.name : rowData.original_name}
                                    unmountFolderDocumentsGet={props.unmountFolderDocumentsGet}
                                />
                            )
                        },
                    })
            }

            //append a column for Audit History
            if (props.actionsConfig.audit && props.actionsConfig.audit.document && props.actionsConfig.audit.document.enabled) {
                fixedColumnsRight++
                columns.push({
                    title: '',
                    field: 'id',
                    filtering: false,
                    sorting: false,
                    width: 32,
                    cellStyle: {
                        width: 32,
                        //maxWidth: 48,
                        padding: 0,
                        // marginRight: 40
                    },
                    headerStyle: {
                        width: 32,
                        //maxWidth: 48,
                        padding: 0,
                        // marginRight: 40
                    },
                    disableClick: true,
                    export: false,
                    editComponent: rowData => {
                        return (<span/>)
                    },
                    render: rowData => {
                        return (
                            <AuditButton
                                parentClasses={this.props.classes}
                                userDetails={this.props.userDetails}
                                triggerRefreshAuthToken={this.props.triggerRefreshAuthToken}
                                docId={rowData.id}
                                type={"document"}
                            />
                        )
                    },
                })

            }


            if (props.actionsConfig.documentDropDownMenu.enabled) {
                if (hasAccess(props.actionsConfig.documentDropDownMenu, props.userDetails.userRoles)) {
                    fixedColumnsRight++
                    //append a column to render the document menu
                    columns.push({
                        title: '',
                        field: 'id',
                        filtering: false,
                        sorting: false,
                        width: 32,
                        cellStyle: {
                            width: 32,
                            //maxWidth: 48,
                            paddingRight: 15,
                            paddingLeft: 0,
                            marginRight: 40
                        },
                        headerStyle: {
                            width: 32,
                            //maxWidth: 48,
                            padding: 0,
                            // marginRight: 40
                        },
                        disableClick: true,
                        export: false,
                        editComponent: rowData => {
                            return (<span/>)
                        },
                        render: rowData => {
                            return (
                                <DocumentMenu
                                    boxDocID={rowData.id}
                                    userDetails={this.props.userDetails}
                                    metadataConfig={this.props.metadataConfig}
                                    uploadConfig={this.props.uploadConfig}
                                    workspaceConfig={this.props.workspaceConfig}
                                    selectedWorkspaceConfig={this.props.selectedWorkspaceConfig}
                                    actionsConfig={this.props.actionsConfig}
                                    triggerRefreshAuthToken={this.props.triggerRefreshAuthToken}
                                />
                            )
                        },
                    });
                }
            }
        }

        const hasMultiSelectActions = checkHasMultiSelectActionsFolderDocs(props.actionsConfig, props.userDetails.userRoles);

        //add data for each item in search results

        let data = [];

        props.searchResults.forEach(sr => {
            data.push(sr)
        });

        if (props.selectedWorkspaceConfig.searchConfig.folderDocuments.sortResultsByName) {
            data.sort( dynamicSort("name") );
        }

        //Add a column for edit icon
        const hasEditAccess = hasAccess(this.props.actionsConfig.edit, this.props.userDetails.userRoles);
        if (hasEditAccess && hasEditableColumns) {
            //fixedColumnsRight++
        }


        // console.log ('fixedColumnsLeft=', fixedColumnsLeft )
        // console.log ('fixedColumnsRight=', fixedColumnsRight )

        this.state = {
            searchResults: props.searchResults,
            data: data,
            columns: columns,
            hasMultiSelectActions: hasMultiSelectActions,
            hasEditableColumns: hasEditableColumns,
            hasEditAccess: hasEditAccess,
            enableGrouping : enableGrouping,
            isProcessing: false,
            showPreview: false,
            showPreviewDialog: false,
            enableColumnFiltering: enableColumnFiltering,
            fixedColumnsLeft: enableGrouping ? 0 : fixedColumnsLeft,
            fixedColumnsRight: enableGrouping ? 0 : fixedColumnsRight,
            showDeleteAction: showDeleteAction,
            tableRef: React.createRef(),
            showDownloadFilenameDialog: false,
            selectedFilesForDownload: []
        }
    }

    componentDidUpdate(prevProps) {

        if (JSON.stringify(this.state.searchResults)!== JSON.stringify(this.props.searchResults)){
            this.props.unmountComponent();
         }
        // else {
            //window.location.pathname.toLowerCase().includes("debug") && console.log ('no change to search Results')
        // }
    }

    componentWillUnmount() {
        this.props.remountComponent();
    }

    showDownloadFilenameDialog = (data) => {
        this.setState({
            showDownloadFilenameDialog: true,
            selectedFilesForDownload: data
        });
    }

    closeDownloadFilenameDialog = () => {
        console.log ('closeDownloadFilenameDialog')
        this.setState({
            showDownloadFilenameDialog: false,
            selectedFilesForDownload: []
        });
    }

    updateIsProcessing =(isProcessing, deselect) => {
        this.setState({
            isProcessing: isProcessing
        });

        if (deselect) {
            this.state.tableRef.current.onAllSelected(false)
        }

    }

    rowClickNoAction = (event,rowData) => {
        //dummy action to make row go grey when hovered over
    };

    rowClickOpen = (event, rowData) => {
        this.setState({
            showPreviewDialog: true,
            showPreviewDocId: rowData.id,
            showPreviewDocumentDetails: rowData
        });
    };

    handleClosePreviewDialog = (metadataUpdated) => {

        window.location.pathname.toLowerCase().includes("debug") && console.log ('handleClosePreviewDialog metadataUpdated= ', metadataUpdated);

        this.setState({
            showPreviewDialog: false,
            showPreviewDocId: "",
            showPreviewDocumentDetails: {}
        });
        if (metadataUpdated) {
            if (this.props.unmountFolderDocumentsGet) {
                this.props.unmountFolderDocumentsGet();
            }
        }
    }

    render() {

        const actionsConfig = this.props.actionsConfig;
        const hasEditAccess = this.state.hasEditAccess;
        const folderDetails = this.props.folderDetails;
        const isProcessing = this.state.isProcessing;


        const {t} = this.props;
        const translate = (val) => window.REACT_APP_ENABLE_TRANSLATION === "true" ? t(val) : val

        let columns = this.state.columns;
        columns.forEach(col => col.title = translate(col.label))

        //editable MaterialTable Documentation:
        //https://material-table.com/#/docs/features/editable
        /*
            To make table editable, you should set editable prop that has onRowAdd, onRowUpdate, onRowDelete functions to manipulate data.
            Every function must return a promise.

            To add a custom action

            https://material-table.com/#/docs/features/actions
         */
        //const classes = this.props.classes;

        let actionsColumnIndex = this.state.showDeleteAction ? this.state.columns.length-1 : -1;

        //To cater for Metadata api which doesn't return searchTotalCount
        let totalCountDisp ;

        if ((!this.props.searchTotalCount || this.props.searchTotalCount === 0) || this.props.nextMarker) {
            totalCountDisp = this.props.searchResults.length
        } else {
            totalCountDisp = this.props.searchTotalCount
        }

        let tableTitle =
            numberWithCommas(totalCountDisp) + " " +
                (totalCountDisp === 1 ?
                    translate(this.props.sign ? " sign document" : this.props.selectedWorkspaceConfig.searchConfig.documentSearch.resultsTitleSingular) :
                    translate(this.props.sign ? " sign documents": this.props.selectedWorkspaceConfig.searchConfig.documentSearch.resultsTitlePlural));

        const searchConfig = this.props.selectedWorkspaceConfig.searchConfig.folderDocuments;

        let exportFileName = "";
        const exportFileNameConfig = searchConfig.exportFileName

        if (exportFileNameConfig) {
            exportFileNameConfig.forEach(entry =>{
                if (folderDetails && folderDetails[entry.templateKey + "~" + entry.metadataKey]) {
                    const val = folderDetails[entry.templateKey + "~" + entry.metadataKey]
                    exportFileName = exportFileName === "" ? val : exportFileName + " - " + val
                }
            })
        }

        //Determine icon & iconColour from config
        const groupingIcon = searchConfig.resultsDisplay.table && searchConfig.resultsDisplay.table.groupingIcon ?
            searchConfig.resultsDisplay.table.groupingIcon : {};

        const iconColourDefault = this.props.theme.palette.secondary.main;
        let iconColour = iconColourDefault; //default colour


        if (searchConfig.resultsDisplay.table && searchConfig.resultsDisplay.table.groupingIconColour) {
            if (searchConfig.resultsDisplay.table.groupingIconColour.default) {
                iconColour = searchConfig.resultsDisplay.table.groupingIconColour.default
            }
            //check if a custom field is provided
            if (searchConfig.resultsDisplay.table.groupingIconColour.custom && searchConfig.resultsDisplay.table.groupingIconColour.custom.templateKey && searchConfig.resultsDisplay.table.groupingIconColour.custom.metadataKey) {
                const templateKey = searchConfig.resultsDisplay.table.groupingIconColour.custom.templateKey;
                const metadataKey = searchConfig.resultsDisplay.table.groupingIconColour.custom.metadataKey;
                //check if value available on folder
                let val
                if (this.props.folderDetails[templateKey + "~" + metadataKey]) {
                    val = this.props.folderDetails[templateKey + "~" + metadataKey]
                }
                if (val) {
                    let fieldConfig = getFieldConfig(this.props.metadataConfig, searchConfig.resultsDisplay.table.groupingIconColour.custom.templateKey, searchConfig.resultsDisplay.table.groupingIconColour.custom.metadataKey)
                    if (fieldConfig && fieldConfig.options && fieldConfig.options.length > 0 ) {
                        let option = getObjectByKey("value", val, fieldConfig.options);
                        if (option && option.groupingIconColour) {
                            iconColour = option.groupingIconColour
                        }
                    }
                }
            }
        }
        //

        let hasMore = false;
        if (this.props.nextMarker) {
            hasMore = true
        } else if (this.props.searchTotalCount !== 0 && (this.props.searchResults.length < this.props.searchTotalCount)) {
            hasMore = true
        }

        //Access to Add Doc button now further restricted by roles listed in workspaceConfig.uploadConfig (if available)
        const workspaceUploadRoles =
            this.props.selectedWorkspaceConfig.uploadConfig.roles &&
            Array.isArray(this.props.selectedWorkspaceConfig.uploadConfig.roles) ?
                this.props.selectedWorkspaceConfig.uploadConfig.roles :
                [];

        const showAddDocButton =
            this.props.disableAddDocument ? false :
                this.props.actionsConfig.addDocumentWithinFolder.enabled && workspaceUploadRoles.length > 0 ?
                    hasAccess(this.props.selectedWorkspaceConfig.uploadConfig, this.props.userDetails.userRoles) :
                    hasAccess(this.props.actionsConfig.addDocumentWithinFolder, this.props.userDetails.userRoles)

        const folderSearchConfig = this.props.selectedWorkspaceConfig.searchConfig.folderSearch;
        const subfolderGroupMembersConfig = folderSearchConfig.subfolderDetails && folderSearchConfig.subfolderDetails.subfolderGroupMembers;
        const showSubfolderGroupMembers =  this.props.showingSubfolderDocs && subfolderGroupMembersConfig && subfolderGroupMembersConfig.show;

        const fileUrlPropertyOptions = this.props.actionsConfig.previewDocument.pdfViewer && this.props.actionsConfig.previewDocument.pdfViewer.fileUrlPropertyOptions ?
            this.props.actionsConfig.previewDocument.pdfViewer.fileUrlPropertyOptions : [];

        const showExport = actionsConfig.exportResults && actionsConfig.exportResults.enabled && hasAccess(actionsConfig.exportResults, this.props.userDetails.userRoles)
        const colsForExport = this.state.columns.filter((col) => col.field !== 'id');

        console.log ('cols=', this.state.columns);
        console.log ('colsForExport=', colsForExport)

        return (
            <StyledEngineProvider injectFirst>
                <ThemeProvider theme={tableTheme}>
                    <React.Fragment>
                        <InfiniteScroll
                            dataLength={this.props.searchResults.length}
                            next={this.props.getNextResults}
                            hasMore={hasMore}
                            loader={
                                <InfiniteScrollLoader
                                    searchReturnedCount={this.props.searchResults.length}
                                    searchTotalCount={this.props.searchTotalCount}
                                    searchLimit={this.props.searchLimit}
                                    isFetching={this.props.isFetching}
                                    nextMarker={this.props.nextMarker}
                                    getNextResults={this.props.getNextResults}
                                />
                            }
                            endMessage={
                                <InfiniteScrollEndMessage
                                    searchReturnedCount={this.props.searchResults.length}
                                    searchTotalCount={this.props.searchTotalCount}
                                    nextMarker={this.props.nextMarker}
                                />
                            }
                            // scrollableTarget="scrollableDiv1"
                        >
                            <MaterialTable
                                tableRef={this.state.tableRef}
                                title={
                                    <React.Fragment>
                                        <div style={{display: "inline"}}>

                                            <Typography  variant={"h6"} color={"textPrimary"}>
                                                {tableTitle}
                                                <DisplayOptionButtons searchConfig = {searchConfig}
                                                                      showResultsAsGrid={this.props.showResultsAsGrid}
                                                                      showResultsAsTable={this.props.showResultsAsTable}
                                                                      currentDisplay={"table"}
                                                />
                                                {
                                                    hasMore &&
                                                    <Tooltip title={this.props.isFetching ? "Retrieving more results..." : "Retrieve more results"}>
                                                        <span>
                                                            <Button onClick={this.props.getNextResults}
                                                                    key={"ibNext"}
                                                                    color={"secondary"}
                                                                    disabled={this.props.isFetching}
                                                                    style={{fontWeight: 'bold', minWidth: '0px',paddingLeft: '5px',paddingRight: '5px',paddingTop: '5px', fontSize: '1rem'}}>
                                                                {this.props.isFetching ?
                                                                    <CircularProgress color="secondary" size={15}/> :
                                                                    "..."}
                                                            </Button>
                                                        </span>
                                                    </Tooltip>
                                                }
                                                {
                                                    this.props.actionsConfig.searchCriteriaTooltip.enabled &&
                                                    <SearchCriteriaTooltip
                                                        parentClasses={this.props.classes}
                                                        metadataConfig={this.props.metadataConfig}
                                                        searchCriteriaSimple={this.props.searchCriteriaSimple}
                                                        searchCriteria={this.props.searchCriteria}
                                                        iconClass={""}
                                                    />
                                                }
                                                {
                                                    this.props.actionsConfig.refreshFolderDocuments.enabled &&
                                                    <FolderDocumentsRefresh
                                                        unmountFolderDocumentsGet={this.props.unmountFolderDocumentsGet}
                                                    />
                                                }

                                                {
                                                    !this.props.sign && showAddDocButton &&
                                                    <div style={{display: "inline"}}>
                                                        <AddDocButton
                                                            parentClasses={this.props.classes}
                                                            userDetails={this.props.userDetails}
                                                            uploadConfig={this.props.selectedWorkspaceConfig.uploadConfig}
                                                            metadataConfig={this.props.metadataConfig}
                                                            optionsConfig={this.props.optionsConfig}
                                                            folderDetails={this.props.folderDetails}
                                                            iconClass={""}
                                                            unmountFolderDocumentsGet={this.props.unmountFolderDocumentsGet}
                                                            triggerRefreshAuthToken={this.props.triggerRefreshAuthToken}
                                                            actionConfig={this.props.actionsConfig.addDocumentWithinFolder}
                                                            workspaceConfig={this.props.workspaceConfig}
                                                            selectedWorkspaceConfig={this.props.selectedWorkspaceConfig}
                                                            templatesConfig={this.props.templatesConfig}
                                                            actionsConfig={this.props.actionsConfig}
                                                        />
                                                    </div>
                                                }
                                                {
                                                    this.props.actionsConfig.filterFolderDocuments.enabled &&
                                                    hasAccess(this.props.actionsConfig.filterFolderDocuments, this.props.userDetails.userRoles) &&
                                                    <FilterFolderDocuments
                                                        parentClasses={this.props.classes}
                                                        filterFolderDocumentsActionConfig={this.props.actionsConfig.filterFolderDocuments}
                                                        toggleFilterFolderDocuments={this.props.toggleFilterFolderDocuments}
                                                        filterFolderDocuments={this.props.filterFolderDocuments}
                                                        isFiltering={this.props.isFilteringFolderDocuments}
                                                    />
                                                }
                                                {
                                                    this.props.actionsConfig.filterFolderDocumentsByMetadata.enabled &&
                                                        !this.props.disableFilterFolderDocumentsByMetadata &&
                                                    hasAccess(this.props.actionsConfig.filterFolderDocumentsByMetadata, this.props.userDetails.userRoles) &&
                                                    <FilterFolderDocumentsByMetadata
                                                        parentClasses={this.props.classes}
                                                        filterFolderDocumentsByMetadataActionConfig={this.props.actionsConfig.filterFolderDocumentsByMetadata}
                                                        toggleFilterFolderDocumentsByMetadata={this.props.toggleFilterFolderDocumentsByMetadata}
                                                        filterFolderDocumentsByMetadata={this.props.filterFolderDocumentsByMetadata}
                                                        isFiltering={this.props.isFilteringFolderDocumentsByMetadata}
                                                        triggerRefreshAuthToken={this.props.triggerRefreshAuthToken}
                                                    />
                                                }
                                                {
                                                    this.props.searchResults.length > 0 &&
                                                    this.props.actionsConfig.downloadResults.enabled &&
                                                    hasAccess(this.props.actionsConfig.downloadResults, this.props.userDetails.userRoles) &&
                                                    <div style={{display: "inline"}}>
                                                        <DownloadResults
                                                            userDetails={this.props.userDetails}
                                                            downloadResultsActionConfig={this.props.actionsConfig.downloadResults}
                                                            searchResults={this.props.searchResults}
                                                            fileName = {this.props.folderDetails.name + ".zip"}
                                                            triggerRefreshAuthToken={this.props.triggerRefreshAuthToken}
                                                        />
                                                    </div>
                                                }
                                                {
                                                    showSubfolderGroupMembers &&
                                                    <div style={{display: "inline"}}>
                                                        <SubfolderGroupMembersButton
                                                            subfolderGroupMembersConfig={subfolderGroupMembersConfig}
                                                            actionsConfig={this.props.actionsConfig}
                                                            folderLabel={this.props.selectedWorkspaceConfig.searchConfig.folderSearch.title}
                                                            classes={this.props.classes}
                                                            triggerRefreshAuthToken={this.props.triggerRefreshAuthToken}
                                                            userDetails={this.props.userDetails}
                                                            folderDetails={this.props.folderDetails}
                                                        />
                                                    </div>
                                                }

                                            </Typography>
                                        </div>
                                    </React.Fragment>
                                }
                                columns={columns}
                                // remove shadow from paper container
                                components={{
                                    // FilterRow: props => <TextField {...props} variant={"standard"}/>,
                                    Container: props => <Paper {...props} elevation={0}/>,
                                    Groupbar: props => <Paper {...props} style={{display: 'none'}}/>,
                                    GroupRow: (props) => <CustomGroupRow {...props} localization= {{body: {dateTimePickerLocalization: idLocale}}}iconColour={iconColour} groupingIcon={groupingIcon} optionsConfig={this.props.optionsConfig}/>
                                }}
                                data={this.state.data}
                                icons={{
                                    DetailPanel: () => <i className={'material-icons'} style={{color: iconColour}}>chevron_right</i>,
                                    Edit: () => <i className={'material-icons'} style={{color: iconColourDefault}}>edit</i>,
                                    Check: () => <i className={'material-icons'} style={{color: iconColourDefault, padding: '3px'}}>check</i>,
                                    Clear: () => <i className={'material-icons'} style={{color: iconColourDefault, padding: '3px'}}>clear</i>,
                                    Export: () => <ExportIcon style={{height: '24px', width: '24px', color: iconColour}}/>
                                }}
                                options={{
                                    searchFieldVariant: "standard",

                                    //exportButton: actionsConfig.exportResults && actionsConfig.exportResults.enabled && hasAccess(actionsConfig.exportResults, this.props.userDetails.userRoles),
                                    //exportFileName: exportFileName ? exportFileName : "export",
                                    //exportAllData: true, //to export hidden columns that are flagged for export

                                    exportMenu: showExport ? [{
                                        label: 'Export PDF',
                                        exportFunc: (cols, datas) => ExportPdf(colsForExport, datas, exportFileName ? exportFileName : "export")
                                    }, {
                                        label: 'Export CSV',
                                        exportFunc: (cols, datas) => ExportCsv(colsForExport, datas, exportFileName ? exportFileName : "export")
                                    }]: [],
                                    filtering: this.state.enableColumnFiltering && this.state.data.length > 0,
                                    grouping: this.state.enableGrouping,
                                    groupRowSeparator: " ", //to prevent colon from appearing between label and value on grouping
                                    actionsColumnIndex: actionsColumnIndex,
                                    paging: false,
                                    actionsCellStyle: {
                                        color: "rgba(0, 0, 0, 0.54)",
                                        width: 32,
                                        maxwidth: 32,
                                        paddingLeft: 0,
                                        paddingRight: 0
                                    },
                                    headerStyle: {
                                        width: 32, //NB required to control width of detail panel expansion icon
                                        paddingLeft: 0,
                                        paddingRight: 0
                                    },
                                    selection: this.state.hasMultiSelectActions,
                                    selectionProps: rowData => ({
                                        size: 'medium'
                                    })

                                    // tableLayout: 'fixed', - Note having tableLayout=fixed causes Cancel button to scroll off screen but removing it makes date columns too narrow

                                    //note fixed columns not available when grouping https://material-table.com/#/docs/features/fixed-columns
                                    //columnResizable: true, //NB, requires width to be set for each column
                                    //Note - value in fixedColumns breaks onChange - newData paramater is returned the same as oldData in onChangeEvent
                                    // fixedColumns: {
                                    //left: this.state.fixedColumnsLeft,
                                    //right: this.state.fixedColumnsRight
                                    //left: -1,
                                    //right: -1
                                    // }
                                }}
                                onRowClick={
                                    this.props.actionsConfig.previewDocument.openOnRowClick && hasAccess(this.props.actionsConfig.previewDocument, this.props.userDetails.userRoles)?
                                        this.rowClickOpen :
                                        this.rowClickNoAction
                                }
                                actions={ this.state.hasMultiSelectActions ?
                                    [
                                        this.props.actionsConfig.downloadFileMultiFolderDocs?.enabled &&
                                        {
                                            // tooltip: isProcessing ? "Downloading..." : this.props.actionsConfig.downloadFileMulti.label,
                                            tooltip: !isProcessing && this.props.actionsConfig.downloadFileMultiFolderDocs.label,
                                            icon: 'file_download',
                                            iconProps: {  color: isProcessing? "grey" : "secondary"  },
                                            disabled: isProcessing,
                                            onClick: (evt, data) => {
                                                if (data.length > 1 && this.props.actionsConfig.downloadFileMulti.promptForFilename) {
                                                    this.showDownloadFilenameDialog(data);
                                                } else {
                                                    downloadFileMulti( data, this.props.userDetails.accessToken,this.props.triggerRefreshAuthToken, this.updateIsProcessing)
                                                }

                                            }
                                        },
                                    ] : []
                                }

                                editable={
                                    this.props.sign || !this.props.actionsConfig.edit.enabled ?
                                        {} :
                                        !hasEditAccess ?
                                            {} :
                                            !this.state.hasEditableColumns ?
                                                {} :
                                                {
                                                    onRowUpdate: (newData, oldData) =>


                                                        //TODO if field value not in list of options then set it to blank

                                                        new Promise (async (resolve, reject)  => {

                                                            window.location.pathname.toLowerCase().includes("debug") && console.log ('oldData=', oldData, 'newData = ', newData);
                                                            // if (this.state.validationError) {
                                                            //     alert (this.state.validationErrorDetails)
                                                            // }

                                                            let allFieldsValid = true;
                                                            let invalidDate = false;
                                                            let metadataArray = [];
                                                            Object.entries(newData).forEach(entry => {
                                                                const templateKey = entry[0].split("~")[0];
                                                                const metadataKey = entry[0].split("~")[1]
                                                                if (entry[0].indexOf("~") > -1) {
                                                                    let val = entry[1];
                                                                    if (val && typeof val === 'object') {
                                                                        if (validateDate(val)) {
                                                                            let dateVal = new Date(val)
                                                                            dateVal.setUTCHours(0, 0, 0, 0);
                                                                            val = dateVal;
                                                                        } else {
                                                                            invalidDate = true;
                                                                            allFieldsValid = false
                                                                        }
                                                                    }
                                                                    //if fieldConfig specifies number but we've got text here, convert it (to address issue with material-table saving text for numeric dropdown fields)
                                                                    if (isNumeric(val)){
                                                                        let fieldConfig = getFieldConfig(this.props.metadataConfig, templateKey, metadataKey)
                                                                        if (fieldConfig && fieldConfig.type==="number") {
                                                                            val = parseInt(val)
                                                                        }
                                                                    }

                                                                    metadataArray.push({
                                                                        templateKey: templateKey,
                                                                        metadataKey: metadataKey,
                                                                        value: val
                                                                    });
                                                                }
                                                            });

                                                            if (!allFieldsValid) {
                                                                const message = (invalidDate ? "Invalid date.  " : "") + "Please ensure all fields are valid before saving."
                                                                enqueueSnackbar(message, {variant: 'info'});
                                                                reject();

                                                            } else {

                                                                let body = {
                                                                    fileName: newData.name,
                                                                    metadata: metadataArray,
                                                                };


                                                                const pathVar = "/" + newData.id;
                                                                const params = '?audit=' + this.props.actionsConfig.edit.audit;
                                                                const url = window.REACT_APP_CONTENT_API_BASE_URL + window.REACT_APP_CONTENT_API_DOCUMENT + pathVar + params;
                                                                const request = {
                                                                    method: 'PATCH',
                                                                    headers: {
                                                                        "Content-Type": "application/json",
                                                                        "Authorization": "Bearer " + this.props.userDetails.accessToken
                                                                    },
                                                                    body: JSON.stringify(body)
                                                                };

                                                                window.location.pathname.toLowerCase().includes("debug") && console.log('edit metadata BODY=', body, 'request=', request);
                                                                this.setState({isFetching: true});

                                                                await this.props.triggerRefreshAuthToken();
                                                                fetch(url, request)
                                                                    .then(response => {
                                                                        if (response.ok) {
                                                                            window.location.pathname.toLowerCase().includes("debug") && console.log('response=', response);
                                                                            enqueueSnackbar("Metadata on " + body.fileName + " successfully updated.", {variant: 'success'});
                                                                            this.setState({isFetching: false});

                                                                            //update state data so that table shows latest update
                                                                            const dataUpdate = [...this.state.data];
                                                                            const target = dataUpdate.find((el) => el.id === oldData.tableData.id);
                                                                            const index = dataUpdate.indexOf(target);
                                                                            dataUpdate[index] = newData;
                                                                            this.setState({
                                                                                ...this.state,
                                                                                data: dataUpdate
                                                                            });

                                                                            resolve();

                                                                        } else {
                                                                            window.location.pathname.toLowerCase().includes("debug") && console.log('response=', response);
                                                                            Promise.resolve(getErrorMessageFromResponse(response, 'updating data'))
                                                                                .then(message => {
                                                                                    enqueueSnackbar(message, {variant: 'error'});
                                                                                    window.location.pathname.toLowerCase().includes("debug") && console.log("updateMetadata error.  url:", url, "request: ", request);
                                                                                    this.setState({isFetching: false});
                                                                                    reject();
                                                                                })
                                                                        }
                                                                    })
                                                            }
                                                            // .catch(e => {
                                                            //     enqueueSnackbar("Error updating data.  Exception: " + e);
                                                            //     window.location.pathname.toLowerCase().includes("debug") && console.log("updateMetadata Exception:", e, " url:", url, "request: ", request, );
                                                            //     this.setState({isFetching: false})});
                                                            //     reject();
                                                        }),
                                                }
                                }
                                detailPanel={
                                    [
                                        {
                                            tooltip: 'Preview',
                                            disabled: false,
                                            style: {color: iconColour},
                                            width: '48px',
                                            // cellStyle: {
                                            //     width: '48px',
                                            //     maxWidth: '50px',
                                            //     padding: 0,
                                            //     // marginRight: 40
                                            // },
                                            // headerStyle: {
                                            //    // width: '48px',
                                            //    // maxWidth: '50px',
                                            //     padding: 0,
                                            //     // marginRight: 40
                                            // },
                                            render: rowData => {
                                                //get fileUrl field if available
                                                let fileUrl="";
                                                for (let i=0; i<fileUrlPropertyOptions.length; i++) {
                                                    if (rowData[fileUrlPropertyOptions[i]]) {
                                                        fileUrl = rowData[fileUrlPropertyOptions[i]];
                                                        break;
                                                    }
                                                }

                                                return (
                                                    <div style={{height: "500px"}}>
                                                        <Preview
                                                            boxDocID={rowData.rowData.id}
                                                            fileUrl={fileUrl}
                                                            userDetails={this.props.userDetails}
                                                            showHeader={false}
                                                            triggerRefreshAuthToken={this.props.triggerRefreshAuthToken}
                                                            actionsConfig={this.props.actionsConfig}
                                                        />
                                                    </div>
                                                )
                                            }
                                        }
                                    ]
                                }
                                localization={{
                                    body: {
                                        dateTimePickerLocalization: idLocale,
                                        emptyDataSourceMessage: '',
                                        editTooltip: this.props.actionsConfig.edit.label,
                                        editRow: {
                                            cancelTooltip: 'Cancel Edit'
                                        }
                                    },
                                    toolbar: {
                                        searchTooltip: 'Filter results',
                                        searchPlaceholder: 'Filter results'
                                    }
                                }}
                            />
                        </InfiniteScroll>

                        {
                            (this.state.showPreviewDialog) &&
                            <PreviewDialog
                                parentClasses={this.props.classes}
                                boxDocID={this.state.showPreviewDocId}
                                userDetails={this.props.userDetails}
                                handleCloseDialog={this.handleClosePreviewDialog}
                                calledFromDocumentMenu={true}
                                metadataConfig={this.props.metadataConfig}
                                optionsConfig={this.props.optionsConfig}
                                workspaceConfig={this.props.workspaceConfig}
                                selectedWorkspaceConfig={this.props.selectedWorkspaceConfig}
                                actionsConfig={this.props.actionsConfig}
                                triggerRefreshAuthToken={this.props.triggerRefreshAuthToken}
                                showMetadataOnOpen={this.props.showMetadataOnOpen}
                                documentDetails={this.state.showPreviewDocumentDetails}
                                documentMetadataFields={searchConfig && searchConfig.documentMetadataFields}

                            />
                        }

                        {
                            this.state.showDownloadFilenameDialog &&
                            <DownloadFilenameDialog
                                files={this.state.selectedFilesForDownload}
                                accessToken={this.props.userDetails.accessToken}
                                triggerRefreshAuthToken={this.props.triggerRefreshAuthToken}
                                updateIsProcessing={this.updateIsProcessing}
                                handleCloseDialog={this.closeDownloadFilenameDialog}
                            />
                        }

                    </React.Fragment>
                </ThemeProvider>
            </StyledEngineProvider>
        );
    }
}

SearchResultsDocumentsFolderDocsTable.propTypes = {
    classes: PropTypes.object.isRequired,
    searchResults: PropTypes.array.isRequired,
    searchTotalCount: PropTypes.number.isRequired,
    searchLimit: PropTypes.number.isRequired,
    getNextResults: PropTypes.func.isRequired,
    getAllResults: PropTypes.func.isRequired,
    userDetails: PropTypes.object.isRequired,
    actionsConfig: PropTypes.object.isRequired,
    optionsConfig: PropTypes.object.isRequired,
    metadataConfig: PropTypes.object.isRequired,
    workspaceConfig: PropTypes.array.isRequired,
    selectedWorkspaceConfig: PropTypes.object.isRequired,
    folderDetails: PropTypes.object.isRequired,
    isFetching: PropTypes.bool.isRequired,
    unmountComponent: PropTypes.func.isRequired,
    remountComponent: PropTypes.func.isRequired,
    searchCriteria: PropTypes.array.isRequired,
    searchCriteriaSimple: PropTypes.string.isRequired,
    unmountFolderDocumentsGet: PropTypes.func,
    toggleFilterFolderDocuments: PropTypes.func,
    filterFolderDocuments: PropTypes.bool,
    isFilteringFolderDocuments: PropTypes.bool,
    toggleFilterFolderDocumentsByMetadata: PropTypes.func,
    filterFolderDocumentsByMetadata: PropTypes.bool,
    disableFilterFolderDocumentsByMetadata: PropTypes.bool,
    isFilteringFolderDocumentsByMetadata: PropTypes.bool,
    triggerRefreshAuthToken: PropTypes.func.isRequired,
    folderDocuments: PropTypes.bool,
    nextMarker: PropTypes.string,
    templatesConfig: PropTypes.object.isRequired,
    workflowConfig: PropTypes.array.isRequired,
    updateFolderDetails: PropTypes.func.isRequired,
    showResultsAsTable: PropTypes.func.isRequired,
    showResultsAsGrid: PropTypes.func.isRequired,
    sign: PropTypes.bool, //temp for demo
    disableAddDocument: PropTypes.bool, //set in PortalApp to prevent upload when not in a subfolder
    showingSubfolderDocs: PropTypes.bool
};

export default withTranslation()((withStyles(styles, { withTheme: true })(SearchResultsDocumentsFolderDocsTable)));

