import React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import PropTypes from 'prop-types';
import { enqueueSnackbar } from 'notistack'
import LinearProgress from "@mui/material/LinearProgress";
import {withStyles} from "@mui/styles";
import {getErrorMessageFromResponse} from "../../common/helper";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";

const styles = theme => ({
    progressMessage: {
        color: '#808080',
        fontSize: '0.75rem',
        fontWeight: '500',
        lineHeight: '24px',
        paddingLeft: theme.spacing(1)
    },
    progressBar: {
        marginTop: -theme.spacing(1)
    },
})

class CompareParentDialog extends React.Component {
    state = {
        open: false,
        url: ""
    };

    UNSAFE_componentWillMount() {
        this.compare();
    }

    componentDidMount(){
        this.setState({ open: true })
    }


    handleCloseDialog = () => {
        this.setState({ open: false });
        this.props.handleCloseDialog()
    };

    compare = async () => {
        /*
        GET /api/box/{sourceId}/compare/{targetId}
        gets contents, if they aren't .PDF then it attempts to convert them using MS Graph API, then sends them to draftable and returns the public link
         */
        this.setState({ isFetching: true})

        const url = window.REACT_APP_CONTENT_API_BASE_URL + '/api/box/' + this.props.id + '/compare/' + this.props.parentFileId ;

        const request = {
            method: 'GET',
            headers: {"Authorization": "Bearer " + this.props.userDetails.accessToken}
        };

        window.location.pathname.toLowerCase().includes("debug") && console.log ((new Date()).toLocaleTimeString() + " compare url :", url);

        await this.props.triggerRefreshAuthToken();
        fetch(url , request )
            .then(response => {
                window.location.pathname.toLowerCase().includes("debug") && console.log ((new Date()).toLocaleTimeString() + " compare response = ", response);
                if (response.ok) {
                    return(response.text())
                } else {
                    Promise.resolve(getErrorMessageFromResponse(response, 'generating comparison'))
                        .then(message => {
                            enqueueSnackbar(message, {variant: 'error'})
                        })
                    window.location.pathname.toLowerCase().includes("debug") && console.log("compare error.  url:", url, "request: ", request, "response.text:", response);
                    this.handleCloseDialog();
                    return null

                }
            })
            .then(result => {
                window.location.pathname.toLowerCase().includes("debug") && console.log('compare response.text =', result);

                this.setState({
                    url: result,
                    isFetching: false
                })


            })

    }


    render() {

        const classes = this.props.classes;

        return (

            <Dialog
                open={this.state.open}
                onClose={this.handleCloseDialog}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
                fullWidth={true}
                maxWidth="xl"
            >
                <IconButton
                    aria-label="close"
                    onClick={this.handleCloseDialog}
                    color={"secondary"}
                    sx={{position: 'absolute', right: 0, top: 0,}}
                >
                    <CloseIcon />
                </IconButton>

                <DialogContent>

                    <div style={{ border: 'border:1px solid #eee', height:'90vh', width:'100%'}}>
                        <div style={{height: '100%'}}>
                            {
                                this.state.isFetching ?
                                    <React.Fragment>
                                        <LinearProgress variant={"indeterminate"} color={"primary"}/>
                                        <div className={classes.progressMessage}>Preparing files for comparison...</div>
                                    </React.Fragment>
                                    :
                                    <iframe
                                        title={this.state.url}
                                        src={this.state.url}
                                        frameBorder={0}
                                        scrolling={'auto'}
                                        style={{ display:'block', width:'100%', height: '90vh'}}
                                    />
                            }
                        </div>
                    </div>

                </DialogContent>

                <DialogActions>
                    <Button
                        onClick={this.handleCloseDialog}
                        variant="contained"
                        color="secondary">
                        {this.state.isFetching ? 'Cancel' : 'Close'}
                    </Button>
                </DialogActions>
            </Dialog>
        );
    }
}

CompareParentDialog.propTypes = {
    classes: PropTypes.object.isRequired,
    id: PropTypes.string.isRequired,
    parentFileId: PropTypes.string.isRequired,
    handleCloseDialog: PropTypes.func.isRequired,
    userDetails: PropTypes.object.isRequired,
    triggerRefreshAuthToken: PropTypes.func.isRequired
};

export default (withStyles(styles, { withTheme: true} )(CompareParentDialog));