import React, { useContext} from 'react';
import { BrowserRouter as Router, Route, useHistory } from 'react-router-dom';
import {Security, SecureRoute, LoginCallback} from '@okta/okta-react';
import { OktaAuth,toRelativeUrl } from '@okta/okta-auth-js';
import Home from './Home';
import CustomLoginComponent from '../auth/LoginPage';
import { Auth} from 'aws-amplify';
import '@aws-amplify/ui-react/styles.css';
import HomeCognito from "./HomeCognito";
import HomeOkta from "./HomeOkta";
import { UserContext} from "../auth/cognito/UserContext";
import LoginPage from '../auth/cognito/LoginPage'

//Add script element for dynatrace
if (window.REACT_APP_DYNATRACE_URL) {
    const script = document.createElement("script");
    script.src = window.REACT_APP_DYNATRACE_URL;
    script.type = "text/javascript";
    script.crossOrigin = "anonymous";
    console.log('append script: ', script)
    document.body.appendChild(script);
} else {
    console.log('Dynatrace not enabled')
}

const authenticateWith = window.REACT_APP_AUTHENTICATE_WITH;
console.log ('authenticateWith =', authenticateWith);


const HasAccessToRouter = () => {

    console.log ("REACT_APP_CASE_TOKEN_REQUIRED = ", window.REACT_APP_CASE_TOKEN_REQUIRED);
    const caseTokenRequired = window.REACT_APP_CASE_TOKEN_REQUIRED === 'true';
    console.log ('caseTokenRequired =', caseTokenRequired)

    const boxTokenRequired = window.REACT_APP_FOLDER_SOURCE === "box" ||
        window.REACT_APP_DOCUMENT_SOURCE === "box" ||
        window.REACT_APP_TASK_SOURCE === "box" ||
        window.REACT_APP_COMMENT_SOURCE === "box"

    console.log ('boxTokenRequired =', boxTokenRequired)
    const noTokenRequired = !boxTokenRequired && window.REACT_APP_CASE_TOKEN_REQUIRED === 'false'

    console.log ('noTokenRequired = ' , noTokenRequired)

    const history = useHistory(); // example from react-router
    const customAuthHandler = () => {
        // Redirect to the /login page that has a CustomLoginComponent
        history.push('/login');
    };

    const restoreOriginalUri = async (_oktaAuth, originalUri) => {
        history.replace(toRelativeUrl(originalUri || '/', window.location.origin));
    };

    //OpenID Connect configuration
    const oidcConfig = {
        clientId: window.REACT_APP_OKTA_CLIENT_ID,
        issuer: window.REACT_APP_OKTA_BASE_URL + "/oauth2/default",
        redirectUri: window.location.origin + "/implicit/callback",
        scopes: ['openid', 'profile', 'email'],
        pkce: true,
        disableHttpsCheck: false,
        postLogoutRedirectUri: window.location.origin
    }

    const oktaAuth = new OktaAuth(oidcConfig);

    return (
        <Security
            oktaAuth={oktaAuth}
            onAuthRequired={customAuthHandler}
            restoreOriginalUri={restoreOriginalUri}
        >
            {
                noTokenRequired ?
                    // Use HomeOkta if authenticating with Okta, but no tokens required from security api
                    <>
                        {/*<Route path="/implicit/callback" render={(props) => <LoginCallback {...props}  />} />*/}
                        <Route path="/login" component={CustomLoginComponent} />

                        <SecureRoute exact path="/" render={props => <HomeOkta {...props} launchDashboard={false} />} />
                        <SecureRoute exact path="/debug" render={props => <HomeOkta {...props} launchDashboard={false} />} />
                        <SecureRoute exact path="/dashboard" render={props => <HomeOkta {...props} launchDashboard={true} />} />
                        <SecureRoute path="/file/:id" render={props => <HomeOkta {...props} launchDashboard={false} />} />
                        <SecureRoute path="/compare/:compareId" render={props => <HomeOkta {...props} launchDashboard={false} />} />
                        <SecureRoute path="/upload/:workspace" render={props => <HomeOkta {...props} launchDashboard={false} />} />
                        <SecureRoute path="/folder/:folderId" render={props => <HomeOkta {...props} launchDashboard={false} />} />
                        <SecureRoute path="/search" render={props => <HomeOkta {...props} launchDashboard={false} />} />
                    </> :

                    // Use Home if authenticating with Okta, but tokens are required from security api
                    <>
                        <Route exact path="/" render={props => <Home {...props} launchDashboard={false} />} />
                        <Route path="/implicit/callback" render={(props) => <LoginCallback {...props}  />} />
                        <Route path="/login" component={CustomLoginComponent} />

                        <SecureRoute exact path="/debug" render={props => <Home {...props} launchDashboard={false} />} />
                        <SecureRoute exact path="/dashboard" render={props => <Home {...props} launchDashboard={true} />} />
                        <SecureRoute path="/file/:id" render={props => <Home {...props} launchDashboard={false} />} />
                        <SecureRoute path="/compare/:compareId" render={props => <Home {...props} launchDashboard={false} />} />
                        <SecureRoute path="/upload/:workspace" render={props => <Home {...props} launchDashboard={false} />} />
                        <SecureRoute path="/folder/:folderId" render={props => <Home {...props} launchDashboard={false} />} />
                        <SecureRoute path="/search" render={props => <Home {...props} launchDashboard={false} />} />
                    </>


            }

        </Security>
    );
};

function AppOkta() {
    return (
        <div>
            <Router>
                <HasAccessToRouter/>
            </Router>
        </div>
    )
}

function AppCognito() {

    const { user } = useContext(UserContext)

    function signOut () {
        Auth.signOut()
    }

    if (!user) {
        return <LoginPage/>
    } else {
        return (
            <div>
                <React.Fragment>
                    <Router>
                        <Route exact path="/" render={props => <HomeCognito {...props} user={user} logout={signOut} launchDashboard={false} />} />
                        <Route exact path="/debug" render={props => <HomeCognito {...props} user={user} logout={signOut} launchDashboard={false} />} />
                        <Route exact path="/dashboard" render={props => <HomeCognito {...props} user={user} logout={signOut} launchDashboard={true} />} />
                        <Route exact path="/file/:id" render={props => <HomeCognito {...props} user={user} logout={signOut} launchDashboard={false} />} />
                        <Route exact path="/upload/:workspace" render={props => <HomeCognito {...props} user={user} logout={signOut} launchDashboard={false} />} />
                        <Route exact path="/folder/:folderId" render={props => <HomeCognito {...props} user={user} logout={signOut} launchDashboard={false}/>} />
                        <Route exact path="/search" render={props => <HomeCognito {...props} user={user} logout={signOut} launchDashboard={false}/>} />
                    </Router>

                </React.Fragment>
            </div>
        )
    }
}

export default authenticateWith === 'cognito' ? AppCognito : AppOkta;
