import React, { createContext, useState, useEffect } from 'react'
import { Auth, Hub } from 'aws-amplify'
import LinearProgress from "@mui/material/LinearProgress";
export const UserContext = createContext();
export const UserContextProvider = (props) => {

    const [user, setUser] = useState(null)
    const [inProgress, setInProgress] = useState(true)

    Hub.listen('auth', (data) => {
        switch (data.payload.event) {
            case 'signOut':
                setUser(null)
                break
            case 'cognitoHostedUI':
                // console.log('cognitoHostedUI', data)
                break
            default:
                break
        }
    })

    useEffect(() => {
        Hub.listen('auth', ({ payload: { event, data } }) => {
            console.log ('HUB listen event=', event)
            switch (event) {
                case 'cognitoHostedUI':
                    checkUser()
                    break
                case 'signOut':
                    setUser(null)
                    break
            }
        })
        checkUser()
    }, [])

    const checkUser = async () => {
        try {
            console.log ('UserContext.js - checkUser - call Auth.currentAuthenticatedUser()')
            const responseUser = await Auth.currentAuthenticatedUser()
            setUser(responseUser)
            setInProgress(false)
            console.log ('UserContext.js - checkUser - done.  user = ', responseUser)
        } catch (error) {
            console.log ('UserContext.js - checkUser - ERROR - ', error)
            setUser(null)
            setInProgress(false)
        }
    }
    return (
        <>
            {inProgress ? (
                <LinearProgress style={{backgroundColor: '#808080', paddingTop: '50px'}} variant={"indeterminate"}/>
            ) : (
                <UserContext.Provider value={{ user, setUser }}>
                    {props.children}
                </UserContext.Provider>
            )}
        </>
    )
}