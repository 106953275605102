import React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import PropTypes from 'prop-types';
import DialogTitle from "@mui/material/DialogTitle/DialogTitle";
import {withStyles} from '@mui/styles';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import FormControl from "@mui/material/FormControl/FormControl";
import Select from "@mui/material/Select/Select";
import Input from "@mui/material/Input/Input";
import InputAdornment from "@mui/material/InputAdornment/InputAdornment";
import AccountCircle from '@mui/icons-material/AccountCircle';
import Checkbox from '@mui/material/Checkbox';
import List from "@mui/material/List/List";
import ListItem from "@mui/material/ListItem/ListItem";
import ListItemText from "@mui/material/ListItemText/ListItemText";
import { enqueueSnackbar } from 'notistack'
import LinearProgress from "@mui/material/LinearProgress";
import ListSubheader from "@mui/material/ListSubheader/ListSubheader";
import ListItemAvatar from "@mui/material/ListItemAvatar";
import Avatar from "@mui/material/Avatar";
import {green, grey, red} from "@mui/material/colors";
import {getErrorMessageFromResponse} from "../common/helper";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";

const styles = theme => ({
    root: {
        width: '100%',
        marginTop: theme.spacing(3),
        overflowX: 'auto',
    },
    table: {
        minWidth: 700,
    },
});

function getColour(statusIconClass) {

    switch(statusIconClass) {
        case "redAvatar":
            return(red[500]);
        case "greenAvatar":
            return(green[500]);
        default:
            return(grey[500])
    }
}

function ShareProgress(props){

    let collaboratorsWithEmail = [];

    //exclude collaborators with no email
    props.collaborators.forEach(collaborator => {
        if (collaborator.email && collaborator.email.trim() !== "") {
            collaboratorsWithEmail.push(collaborator)
        }
    })

    return(
        <DialogContent>

            {/*Show share progress*/}

            <React.Fragment>
                {
                    (props.shareProgress > 0 && !props.isShareComplete) &&
                    <React.Fragment>
                        <LinearProgress color = "secondary" variant={"indeterminate"} />
                        <ListSubheader disableSticky>Sharing {props.shareProgress} of {collaboratorsWithEmail.length}</ListSubheader>
                    </React.Fragment>
                }

                <List>
                    {collaboratorsWithEmail.map(
                        collaborator => (
                            <React.Fragment key={"frag" + collaborator.name}>
                                <ListItem key={"li" + collaborator.name}>
                                    <ListItemAvatar>
                                        <Avatar style={{backgroundColor: getColour(collaborator.statusIconClass)}}>
                                            <i className='material-icons'>{collaborator.statusIcon ? collaborator.statusIcon : "file_upload"}</i>
                                        </Avatar>
                                    </ListItemAvatar>
                                    <ListItemText key={"liText" + collaborator.email} primary={collaborator.email} secondary={collaborator.statusMessage}/>
                                </ListItem>
                            </React.Fragment>
                        ))}
                </List>
            </React.Fragment>

        </DialogContent>

    )
}

ShareProgress.propTypes = {
    collaborators: PropTypes.array.isRequired,
    shareProgress: PropTypes.number.isRequired,
    isShareComplete: PropTypes.bool.isRequired
};

class ShareDialog extends React.Component {

    constructor(props) {
        super(props);

        this.state = {

            open: false,

            //add initial blank row
            collaborators: [{
                id: 1,
                email: "",
                role: "",
                sendNotification: true //default to true
            }],

            //update this when user clicks Share.  Then update status accordingly
            shareRequest: {},
        };

        this.handleOnChange = this.handleOnChange.bind(this);
        this.handleOnChangeCheckbox = this.handleOnChangeCheckbox.bind(this);
        this.handleCloseDialog = this.handleCloseDialog.bind(this);
        this.share = this.share.bind(this);

    };


    componentDidMount(){
        window.location.pathname.toLowerCase().includes("debug") && console.log('props:', this.props);
        this.setState({ open: true })
    }

    handleCloseDialog = () => {
        this.setState({ open: false });
        if (this.props.handleCloseDialog) {
            this.props.handleCloseDialog()
        }
    };

    handleOnChange = event => {

        let updatedCollaboratorList = this.state.collaborators;
        let whichCollaborator = Number(event.target.name.substring(event.target.name.indexOf("_") + 1)) - 1;
        let fieldName = event.target.name.substring(0, event.target.name.indexOf("_"));

        updatedCollaboratorList[whichCollaborator][fieldName] = event.target.value;

        //add a new row if editing final row email
        if (fieldName === "email" && event.target.value !== "") {
            if (whichCollaborator + 1 === updatedCollaboratorList.length)  {
                let newCollaborator = {
                    id: updatedCollaboratorList.length + 1,
                    email: "",
                    role: "",
                    sendNotification: true
                };

                updatedCollaboratorList.push(newCollaborator);
            }
        }
        this.setState({"collaborators" : updatedCollaboratorList});
    };

    handleOnChangeCheckbox = event => {

        let updatedCollaboratorList = this.state.collaborators;
        let whichCollaborator = Number(event.target.name.substring(event.target.name.indexOf("_") + 1)) - 1;
        let fieldName = event.target.name.substring(0, event.target.name.indexOf("_"));

        updatedCollaboratorList[whichCollaborator][fieldName] = event.target.checked;

        this.setState({"collaborators" : updatedCollaboratorList});
    };

    shareAsync = () => {

        //async/await with forEach() - see https://codeburst.io/javascript-async-await-with-foreach-b6ba62bbf404
        //generic asyncForEach function - pass array and function to execute as parameters
        async function asyncForEach(array, callback) {
            for (let index = 0; index < array.length; index++) {
                await callback(array[index], index, array);
            }
        }

        //wrap execution of asyncforEach in an async so that we can wait for all to be completed before showing confirmation
        const start = async () => {

            this.setState({showProgress: true});

            let progressCounter = 0;
            let updatedCollaborators = [...this.state.collaborators];
            updatedCollaborators.forEach((item, index, arr) => {
                if (item.email === "") {
                    arr.splice(index, 1);
                }
            });

            await this.props.triggerRefreshAuthToken();
            await asyncForEach(
                updatedCollaborators,
                async (collaborator) => {
                    progressCounter = progressCounter + 1;

                    window.location.pathname.toLowerCase().includes("debug") && console.log(progressCounter + " - ", collaborator);

                    this.setState({shareProgress: progressCounter});
                    collaborator.statusMessage = "Sharing";
                    collaborator.statusIconClass = "greenAvatar";
                    this.setState({files: updatedCollaborators});

                    if (collaborator.email && collaborator.email.trim() !== "") {

                        let collaboratorAdded = false;
                        await this.share(collaborator)
                            .then(response => {

                                window.location.pathname.toLowerCase().includes("debug") && console.log("COLLABORATE response = ", response);

                                if (response.ok) {
                                    collaborator.statusIcon = "done";
                                    collaborator.statusIconClass = "greenAvatar";
                                    collaborator.statusMessage = "Shared";
                                    collaboratorAdded = true;

                                } else {
                                    Promise.resolve(getErrorMessageFromResponse(response, 'creating collaboration'))
                                        .then(message => {
                                            collaborator.statusIcon = "error";
                                            collaborator.statusIconClass = "redAvatar";
                                            collaborator.statusMessage = message;
                                            enqueueSnackbar(message, {variant: 'error'})
                                        })
                                }
                            })

                        this.setState({collaborators: updatedCollaborators});
                        collaboratorAdded && this.setState({collaboratorsAdded: true});
                    }

                });

            // All Done
            this.setState({isShareComplete: true});
            this.setState({response: {success: true}});

        };

        //start share
        start();

    };


    share = async (collaborator) => {

        try {

            let body = {
                type: "collaboration",
                item : {
                    "id" : this.props.boxId,
                    "type" : this.props.type
                },
                accessible_by: {
                    type: "user",
                    login: collaborator.email
                },
                role: collaborator.role
            };

            window.location.pathname.toLowerCase().includes("debug") && console.log ("BODY:", body);

            const request = {
                method: 'POST',
                headers: {
                    "Content-Type": "application/json",
                    "Authorization": "Bearer " + this.props.userDetails.accessToken
                },
                body: JSON.stringify(body)

            };

            this.setState({isFetching: true});

            const url = window.REACT_APP_CONTENT_API_BASE_URL + window.REACT_APP_CONTENT_API_COLLABORATION;

            await this.props.triggerRefreshAuthToken();
            let response = await (fetch(url, request));
            window.location.pathname.toLowerCase().includes("debug") && console.log('share response = ', response)

            let data = await response.json();
            return data;

        } catch(err) {
            window.location.pathname.toLowerCase().includes("debug") && console.log("share error: " + err);
            return err;
        }

    };

    render() {

        const {classes} = this.props;

            return (
                <div>
                    <Dialog
                        open={this.state.open}
                        onClose={this.handleCloseDialog}
                        aria-labelledby="alert-dialog-title"
                        aria-describedby="alert-dialog-description"
                        fullWidth={false}
                        maxWidth="xl"
                    >
                        <IconButton
                            aria-label="close"
                            onClick={this.handleCloseDialog}
                            color={"secondary"}
                            sx={{position: 'absolute', right: 0, top: 0,}}
                        >
                            <CloseIcon />
                        </IconButton>

                        <DialogTitle>{"Share " + this.props.type}</DialogTitle>

                        {
                            this.state.showProgress ?

                                <ShareProgress collaborators={this.state.collaborators} shareProgress={this.state.shareProgress} isShareComplete={this.state.isShareComplete}/> :

                                <DialogContent>

                                    <DialogContentText sx={(theme)=>({paddingBottom: theme.spacing(2)})}>
                                        Enter collaborator details below
                                    </DialogContentText>

                                    <Paper className={classes.root}>
                                        <Table className={classes.table}>
                                            <TableHead>
                                                <TableRow>
                                                    <TableCell>Email</TableCell>
                                                    <TableCell align="left">Role</TableCell>
                                                    <TableCell align="center">Send Notification</TableCell>
                                                </TableRow>
                                            </TableHead>
                                            <TableBody>
                                                {this.state.collaborators.map(collaborator => (
                                                    <TableRow key={collaborator.id}>
                                                        <TableCell component="th" scope="row">
                                                            <Input
                                                                id={"email_" + collaborator.id}
                                                                name={"email_" + collaborator.id}
                                                                value={collaborator.email}
                                                                placeholder="Enter email address"
                                                                type="email"
                                                                autoComplete="email"
                                                                size="small"
                                                                variant="outlined"
                                                                onChange={this.handleOnChange}
                                                                style={{paddingLeft: "7px", paddingTop: "5px", width: "400px"}}
                                                                startAdornment={
                                                                    <InputAdornment position="start">
                                                                        <AccountCircle/>
                                                                    </InputAdornment>
                                                                }
                                                            />
                                                        </TableCell>
                                                        <TableCell align="left">
                                                            <FormControl  variant="standard" fullWidth>
                                                                <Select
                                                                    id={"role_" + collaborator.id}
                                                                    name={"role_" + collaborator.id}
                                                                    native={true}
                                                                    inputProps={{}}
                                                                    value={collaborator.role}
                                                                    onChange={this.handleOnChange}
                                                                >
                                                                    <option value=""/>
                                                                    <option value="editor">Editor</option>
                                                                    <option value="co-owner">Co-Owner</option>
                                                                    <option value="viewer uploader">Viewer / Uploader</option>
                                                                    <option value="previewer uploader">Previewer / Uploader</option>
                                                                    <option value="viewer">Viewer</option>
                                                                    <option value="previewer">Previewer</option>
                                                                    <option value="uploader">Uploader</option>
                                                                </Select>
                                                            </FormControl>
                                                        </TableCell>
                                                        <TableCell align="center">
                                                            {collaborator.sendNotification}
                                                            <Checkbox
                                                                id={"sendNotification_" + collaborator.id}
                                                                name={"sendNotification_" + collaborator.id}
                                                                checked={collaborator.sendNotification}
                                                                color="default"
                                                                onChange={this.handleOnChangeCheckbox}
                                                            />
                                                        </TableCell>
                                                    </TableRow>
                                                ))}
                                            </TableBody>
                                        </Table>

                                    </Paper>

                                </DialogContent>
                        }

                        <DialogActions>
                            <Button
                                variant="contained"
                                color="grey"
                                onClick={this.handleCloseDialog}>{this.state.showProgress?"Close":"Cancel"}
                            </Button>
                            {
                                (this.state.showProgress) ?
                                    <span/> :
                                    <Button onClick={this.shareAsync} variant="contained" color="secondary">Submit</Button>
                            }
                        </DialogActions>
                    </Dialog>
                </div>
            )
        }
}

ShareDialog.propTypes = {
    classes: PropTypes.object.isRequired,
    boxId: PropTypes.string.isRequired,
    type: PropTypes.string.isRequired, //file or folder
    userDetails: PropTypes.object.isRequired,
    handleCloseDialog:PropTypes.object.isRequired,
    triggerRefreshAuthToken: PropTypes.func.isRequired
};

export default (withStyles(styles) (ShareDialog));