import React, {useState} from "react";
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import PropTypes from 'prop-types';
import { enqueueSnackbar } from 'notistack'
import {withStyles} from '@mui/styles';
import FormControl from '@mui/material/FormControl';
import InputLabel from "@mui/material/InputLabel";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import LinearProgress from "@mui/material/LinearProgress";
import {Grid} from "@mui/material";
import Input from "@mui/material/Input/Input";
import {DatePicker} from "@mui/x-date-pickers";
import {getErrorMessageFromResponse} from "../common/helper";
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import ListItemAvatar from "@mui/material/ListItemAvatar";
import Avatar from "@mui/material/Avatar";
import {green} from "@mui/material/colors";
import TextField from "@mui/material/TextField";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";

const styles = (theme) => ({
    disabled: {}, //required for the rule below to work
    root: {
        '&.Mui-disabled': {
            color: theme.disabledField.colour
        },
        '&:hover': {
            backgroundColor: 'transparent',
        }
    },
    customLabel:{
        color: theme.fieldLabel.colour,
        position: 'relative',
        marginTop: '0px'
    },
    icon: {
        borderRadius: 3,
        width: 16,
        height: 16,
        boxShadow: 'inset 0 0 0 1px rgba(16,22,26,.2), inset 0 -1px 0 rgba(16,22,26,.1)',
        backgroundColor: '#f5f8fa',
        backgroundImage: 'linear-gradient(180deg,hsla(0,0%,100%,.8),hsla(0,0%,100%,0))',
        '$root.Mui-focusVisible &': {
            outline: '2px auto rgba(19,124,189,.6)',
            outlineOffset: 2,
        },
        'input:hover ~ &': {
            backgroundColor: '#ebf1f5',
        },
        'input:disabled ~ &': {
            boxShadow: 'none',
            background: 'rgba(206,217,224,.5)',
        },
    },
    checkedIcon: {
        backgroundColor: '#137cbd',
        backgroundImage: 'linear-gradient(180deg,hsla(0,0%,100%,.1),hsla(0,0%,100%,0))',
        '&:before': {
            display: 'block',
            width: 16,
            height: 16,
            backgroundImage:
                "url(\"data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3E%3Cpath" +
                " fill-rule='evenodd' clip-rule='evenodd' d='M12 5c-.28 0-.53.11-.71.29L7 9.59l-2.29-2.3a1.003 " +
                "1.003 0 00-1.42 1.42l3 3c.18.18.43.29.71.29s.53-.11.71-.29l5-5A1.003 1.003 0 0012 5z' fill='%23fff'/%3E%3C/svg%3E\")",
            content: '""',
        },
        'input:hover ~ &': {
            backgroundColor: '#106ba3',
        },
    },
    rootRadio: {
        '&:hover': {
            backgroundColor: 'transparent',
        },
    },
    iconRadio: {
        borderRadius: '50%',
        width: 16,
        height: 16,
        boxShadow: 'inset 0 0 0 1px rgba(16,22,26,.2), inset 0 -1px 0 rgba(16,22,26,.1)',
        backgroundColor: '#f5f8fa',
        backgroundImage: 'linear-gradient(180deg,hsla(0,0%,100%,.8),hsla(0,0%,100%,0))',
        '$root.Mui-focusVisible &': {
            outline: '2px auto rgba(19,124,189,.6)',
            outlineOffset: 2,
        },
        'input:hover ~ &': {
            backgroundColor: '#ebf1f5',
        },
        'input:disabled ~ &': {
            boxShadow: 'none',
            background: 'rgba(206,217,224,.5)',
        },
    },
    checkedIconRadio: {
        backgroundColor: '#137cbd',
        backgroundImage: 'linear-gradient(180deg,hsla(0,0%,100%,.1),hsla(0,0%,100%,0))',
        '&:before': {
            display: 'block',
            width: 16,
            height: 16,
            backgroundImage: 'radial-gradient(#fff,#fff 28%,transparent 32%)',
            content: '""',
        },
        'input:hover ~ &': {
            backgroundColor: '#106ba3',
        },
    },

});


function SanctionsCreateCaseDialog(props) {

    const debug = window.location.pathname.toLowerCase().includes("debug");

    // top level
    const [umr, setUmr] = useState("");
    const [broker, setBroker] = useState("");
    const [eventType, setEventType] = useState("PREMIUM_WP_CREATE");
    // premium
    const [application, setApplication] = useState("A&S");
    const [workOrderReference, setWorkOrderReference] = useState("");
    const [typeOfSubmission, setTypeOfSubmission] = useState("1");
    const [slipType, setSlipType] = useState("D");
    const [processingRequired, setProcessingRequired] = useState("1");
    const [premPresentationDate, setPremPresentationDate] = useState(null);
    const [premiumFDOLPANS, setPremiumFDOLPANS] = useState(1);
    const [APRPLPANS, setAPRPLPANS] = useState(0);
    const [paidByCheque, setPaidByCheque] = useState("N");
    const [premTransactionType, setPremTransactionType] = useState("OP cash");
    const [premClassOfBusiness, setPremClassOfBusiness] = useState("");
    const [premMarket, setPremMarket] = useState("L");
    // claim
    const [ucr, setUcr] = useState("");
    const [tr, setTr] = useState("");
    const [claimTransactionType, setClaimTransactionType] = useState("003");
    const [claimPresentationDate, setClaimPresentationDate] = useState(null);
    const [claimClassOfBusiness, setClaimClassOfBusiness] = useState("");
    const [claimMarket, setClaimMarket] = useState("L");
    // documents
    const [docCount, setDocCount] = useState(1);

    const [type1, setType1] = useState("");
    const [documentId1, setDocumentId1] = useState("");
    const [documentReference1, setDocumentReference1] = useState("");
    const [documentVersion1, setDocumentVersion1] = useState("");
    const [type2, setType2] = useState("");
    const [documentId2, setDocumentId2] = useState("");
    const [documentReference2, setDocumentReference2] = useState("");
    const [documentVersion2, setDocumentVersion2] = useState("");
    const [type3, setType3] = useState("");
    const [documentId3, setDocumentId3] = useState("");
    const [documentReference3, setDocumentReference3] = useState("");
    const [documentVersion3, setDocumentVersion3] = useState("");
    const [type4, setType4] = useState("");
    const [documentId4, setDocumentId4] = useState("");
    const [documentReference4, setDocumentReference4] = useState("");
    const [documentVersion4, setDocumentVersion4] = useState("");
    const [type5, setType5] = useState("");
    const [documentId5, setDocumentId5] = useState("");
    const [documentReference5, setDocumentReference5] = useState("");
    const [documentVersion5, setDocumentVersion5] = useState("");
    const [type6, setType6] = useState("");
    const [documentId6, setDocumentId6] = useState("");
    const [documentReference6, setDocumentReference6] = useState("");
    const [documentVersion6, setDocumentVersion6] = useState("");
    const [type7, setType7] = useState("");
    const [documentId7, setDocumentId7] = useState("");
    const [documentReference7, setDocumentReference7] = useState("");
    const [documentVersion7, setDocumentVersion7] = useState("");
    const [type8, setType8] = useState("");
    const [documentId8, setDocumentId8] = useState("");
    const [documentReference8, setDocumentReference8] = useState("");
    const [documentVersion8, setDocumentVersion8] = useState("");
    const [type9, setType9] = useState("");
    const [documentId9, setDocumentId9] = useState("");
    const [documentReference9, setDocumentReference9] = useState("");
    const [documentVersion9, setDocumentVersion9] = useState("");
    const [type10, setType10] = useState("");
    const [documentId10, setDocumentId10] = useState("");
    const [documentReference10, setDocumentReference10] = useState("");
    const [documentVersion10, setDocumentVersion10] = useState("");

    const [success, setSuccess] = useState(false);
    const [isProcessing, setIsProcessing] = useState(false);

    function handleCloseDialog() {
        props.handleCloseDialog();
    }

    const handleKeypress = (event) => {
        event.preventDefault();
        return false
    }

    const handleKeyDown = (event) => {
        if (event.key === 'Enter') {
        }
    }

    const onPremPresentationDateChange = (date, value) => {
        setPremPresentationDate(date);
    };

    const onClaimPresentationDateChange = (date, value) => {
        setClaimPresentationDate(date);
    };

    const handleAddDoc = () => {
        if(docCount < 10){
            setDocCount(docCount + 1)
        }
    }

    const handleRemoveDoc = () => {
        if(docCount > 1){
            setDocCount(docCount - 1)
        }
    }

    function validate() {
        
        let isValid = false;
        let invalidFields = "";
        
        // check top level fields...
        if(umr === ""){
            invalidFields = invalidFields + "umr "
        }
        if(broker === ""){
            invalidFields = invalidFields + "broker "
        }
        if(eventType === ""){
            invalidFields = invalidFields + "eventType "
        }
        // check premium fields
        if(eventType ===  "PREMIUM_WP_CREATE" || eventType ===  "PREMIUM_WP_UPDATE"){
            if(application === ""){
                invalidFields = invalidFields + "application "
            }
            if(workOrderReference === ""){
                invalidFields = invalidFields + "workOrderReference "
            }
            if(typeOfSubmission === ""){
                invalidFields = invalidFields + "typeOfSubmission "
            }
            if(slipType === ""){
                invalidFields = invalidFields + "slipType "
            }
            if(processingRequired === ""){
                invalidFields = invalidFields + "processingRequired "
            }
            if(premPresentationDate === null){
                invalidFields = invalidFields + "premPresentationDate "
            }
            if(premiumFDOLPANS === ""){
                invalidFields = invalidFields + "premiumFDOLPANS "
            }
            if(APRPLPANS === ""){
                invalidFields = invalidFields + "APRPLPANS "
            }
            if(paidByCheque === ""){
                invalidFields = invalidFields + "paidByCheque "
            }
            if(premTransactionType === ""){
                invalidFields = invalidFields + "premTransactionType "
            }
            if(premClassOfBusiness === ""){
                invalidFields = invalidFields + "premClassOfBusiness "
            }
            if(premMarket === ""){
                invalidFields = invalidFields + "premMarket "
            }
        }
        // check claim fields
        if(eventType ===  "CLAIM_TRANSACTION_CREATE" || eventType ===  "CLAIM_TRANSACTION_UPDATED"){
            if(ucr === ""){
                invalidFields = invalidFields + "ucr "
            }
            if(tr === ""){
                invalidFields = invalidFields + "tr "
            }
            if(claimTransactionType === ""){
                invalidFields = invalidFields + "claimTransactionType "
            }
            if(claimPresentationDate === null){
                invalidFields = invalidFields + "claimPresentationDate "
            }
            if(claimClassOfBusiness === ""){
                invalidFields = invalidFields + "claimClassOfBusiness "
            }
            if(claimMarket === ""){
                invalidFields = invalidFields + "umr "
            }
        }

        // check document fields
        if(docCount >= 1){
            if(type1 === "" && (eventType ===  "PREMIUM_WP_CREATE" || eventType ===  "PREMIUM_WP_UPDATE")){
                invalidFields = invalidFields + "type 1 "
            }
            if(documentId1 === ""){
                invalidFields = invalidFields + "documentId 1 "
            }
        }

        if(docCount >= 2){
            if(type2 === "" && (eventType ===  "PREMIUM_WP_CREATE" || eventType ===  "PREMIUM_WP_UPDATE")){
                invalidFields = invalidFields + "type 2 "
            }
            if(documentId2 === ""){
                invalidFields = invalidFields + "documentId 2 "
            }
        }
        if(docCount >= 3){
            if(type3 === "" && (eventType ===  "PREMIUM_WP_CREATE" || eventType ===  "PREMIUM_WP_UPDATE")){
                invalidFields = invalidFields + "type 3 "
            }
            if(documentId3 === ""){
                invalidFields = invalidFields + "documentId 3 "
            }
        }
        if(docCount >= 4){
            if(type4 === "" && (eventType ===  "PREMIUM_WP_CREATE" || eventType ===  "PREMIUM_WP_UPDATE")){
                invalidFields = invalidFields + "type 4 "
            }
            if(documentId4 === ""){
                invalidFields = invalidFields + "documentId 4 "
            }
        }
        if(docCount >= 5){
            if(type5 === "" && (eventType ===  "PREMIUM_WP_CREATE" || eventType ===  "PREMIUM_WP_UPDATE")){
                invalidFields = invalidFields + "type 5 "
            }
            if(documentId5 === ""){
                invalidFields = invalidFields + "documentId 5 "
            }
        }
        if(docCount >= 6){
            if(type6 === "" && (eventType ===  "PREMIUM_WP_CREATE" || eventType ===  "PREMIUM_WP_UPDATE")){
                invalidFields = invalidFields + "type 6 "
            }
            if(documentId6 === ""){
                invalidFields = invalidFields + "documentId 6 "
            }
        }
        if(docCount >= 7){
            if(type7 === "" && (eventType ===  "PREMIUM_WP_CREATE" || eventType ===  "PREMIUM_WP_UPDATE")){
                invalidFields = invalidFields + "type 7 "
            }
            if(documentId7 === ""){
                invalidFields = invalidFields + "documentId 7 "
            }
        }
        if(docCount >= 8){
            if(type8 === "" && (eventType ===  "PREMIUM_WP_CREATE" || eventType ===  "PREMIUM_WP_UPDATE")){
                invalidFields = invalidFields + "type 8 "
            }
            if(documentId8 === ""){
                invalidFields = invalidFields + "documentId 8 "
            }
        }
        if(docCount >= 9){
            if(type9 === "" && (eventType ===  "PREMIUM_WP_CREATE" || eventType ===  "PREMIUM_WP_UPDATE")){
                invalidFields = invalidFields + "type 9 "
            }
            if(documentId9 === ""){
                invalidFields = invalidFields + "documentId 9 "
            }
        }
        if(docCount >= 10){
            if(type10 === "" && (eventType ===  "PREMIUM_WP_CREATE" || eventType ===  "PREMIUM_WP_UPDATE")){
                invalidFields = invalidFields + "type 10 "
            }
            if(documentId10 === ""){
                invalidFields = invalidFields + "documentId 10 "
            }
        }

        if(invalidFields === "") {
            isValid = true
        } else {
            console.log("failed validation : " + invalidFields)
            enqueueSnackbar("Please complete all fields before submitting.", {variant: 'error'});
        }

        return isValid
    }

    function Summary() {


        debug && console.log ('Summary: umr=', umr, 'broker code=', broker)
        return (
            <List>
                <React.Fragment key={"frag"}>
                    <ListItem key={"li" + umr}>
                        <ListItemAvatar><Avatar style={{backgroundColor: green[500]}}><i className='material-icons'>done</i></Avatar></ListItemAvatar>
                        <ListItemText key={"liText" + umr} primary={umr + " " + broker + " has been successfully created"}/>
                    </ListItem>
                </React.Fragment>
            </List>

        )
    }

    function handleSubmitClick() {
        if (validate()) {
            submit()
        }
    }

    async function submit() {

        setIsProcessing(true)

        const url = window.REACT_APP_SANCTIONS_CREATE_CASE_URL;

        // created documents array...
        let documents = [{
                "type" : type1,
                "documentId":documentId1,
                "documentReference":documentReference1,
                "documentVersion":documentVersion1
            }];
        if(docCount >= 2){
            documents.push({
                "type" : type2,
                "documentId":documentId2,
                "documentReference":documentReference2,
                "documentVersion":documentVersion2
            });
        }
        if(docCount >= 3){
            documents.push({
                "type" : type3,
                "documentId":documentId3,
                "documentReference":documentReference3,
                "documentVersion":documentVersion3
            });
        }

        if(docCount >= 4){
            documents.push({
                "type" : type4,
                "documentId":documentId4,
                "documentReference":documentReference4,
                "documentVersion":documentVersion4
            });
        }
        if(docCount >= 5){
            documents.push({
                "type" : type5,
                "documentId":documentId5,
                "documentReference":documentReference5,
                "documentVersion":documentVersion5
            });
        }
        if(docCount >= 6){
            documents.push({
                "type" : type6,
                "documentId":documentId6,
                "documentReference":documentReference6,
                "documentVersion":documentVersion6
            });
        }
        if(docCount >= 7){
            documents.push({
                "type" : type7,
                "documentId":documentId7,
                "documentReference":documentReference7,
                "documentVersion":documentVersion7
            });
        }
        if(docCount >= 8){
            documents.push({
                "type" : type8,
                "documentId":documentId8,
                "documentReference":documentReference8,
                "documentVersion":documentVersion8
            });
        }
        if(docCount >= 9){
            documents.push({
                "type" : type9,
                "documentId":documentId9,
                "documentReference":documentReference9,
                "documentVersion":documentVersion9
            });
        }
        if(docCount >= 10){
            documents.push({
                "type" : type10,
                "documentId":documentId10,
                "documentReference":documentReference10,
                "documentVersion":documentVersion10
            });
        }

        // Create a date object from a date string
        let date = "";
        if(eventType ===  "PREMIUM_WP_CREATE"
            || eventType ===  "PREMIUM_WP_UPDATE"){
            date = premPresentationDate;
        } else {
            date = claimPresentationDate;
        }

        // Get year, month, and day part from the date
        var year = date.toLocaleString("default", { year: "numeric" });
        var month = date.toLocaleString("default", { month: "2-digit" });
        var day = date.toLocaleString("default", { day: "2-digit" });

        // Generate yyyy-mm-dd date string
        var formattedDate = year + "-" + month + "-" + day;

        let body = {};

        // create main body details...
        if(eventType ===  "PREMIUM_WP_CREATE"
            || eventType ===  "PREMIUM_WP_UPDATE"){
            body = {
                "SubmissionKey": "test",
                "EventType": eventType,
                "Broker": broker,
                "UMR": umr.toUpperCase(),
                "Premium": {
                    "WorkOrderReference": workOrderReference.toUpperCase(),
                    "WorkOrderUniqueId": workOrderReference.toUpperCase(),
                    "Application": application,
                    "TypeOfSubmission": typeOfSubmission,
                    "SlipType": slipType,
                    "ProcessingRequired": processingRequired,
                    "PremiumFDOLPANS": premiumFDOLPANS,
                    "APRPLPANS": APRPLPANS,
                    "PresentationDate": formattedDate,
                    "TransactionType": premTransactionType,
                    "PaidByCheque": paidByCheque,
                    "ClassOfBusiness": premClassOfBusiness,
                    "Market": premMarket
                },
                "Documents" : documents
            }
        } else if(eventType ===  "CLAIM_TRANSACTION_CREATE"
            || eventType ===  "CLAIM_TRANSACTION_UPDATE"){
            body = {
                "SubmissionKey": "test",
                "EventType": eventType,
                "Broker": broker,
                "UMR": umr.toUpperCase(),
                "Claims": {
                    "UCR": ucr.toUpperCase(),
                    "TR": tr.toUpperCase(),
                    "TransactionType": claimTransactionType,
                    "PresentationDate": formattedDate,
                    "LossDetails": "test loss details...",
                    "BrokerAdditionalDetails": "",
                    "ClassOfBusiness": claimClassOfBusiness,
                    "Market": claimMarket
                },
                "Documents" : documents
            }
        } else {
            debug && console.log("case submission error.  url:", url, "event type is not supported... : ", eventType);
            return null
        }

        const request = {
            method: 'POST',
            headers: {
                "Authorization": "Bearer " + props.userDetails.accessToken,
                "Content-Type": "application/json",
            },
            body: JSON.stringify(body)
        }

        debug && console.log('create case url=', url, 'BODY', body, 'request=', request);

        await props.triggerRefreshAuthToken();

        await fetch(url, request)
            .then(response => {
                debug && console.log('Case submission response=', response);
                if (response.ok) {
                    return(response.status)
                } else {
                    Promise.resolve(getErrorMessageFromResponse(response, 'Case submission'))
                        .then(message => {
                            enqueueSnackbar(message, {variant: 'error'});
                        })
                    debug && console.log("Case submission error.  url:", url, "request: ", request);
                    return null
                }
            })
            .then(data => {
                debug && console.log('Case submission response.json = ', data)
                console.log(data);
                if (data) {
                    setSuccess(true)
                    enqueueSnackbar("Case submission successful" , {variant: 'success'});
                }

                setIsProcessing(false)

            })
            .catch(e => {
                enqueueSnackbar("Case submission (exception: " + e.message + ")" , {variant: 'error'});
                debug && console.log("Case submission exception:" , e);
                setIsProcessing(false)
            })
    }

    const {classes} = props;

    return (
        <div>
            <Dialog
                open={true}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
                fullWidth={true}
                maxWidth= {"md"}
                keepMounted={false}
            >
                <IconButton
                    aria-label="close"
                    onClick={() => handleCloseDialog()}
                    color={"secondary"}
                    sx={{position: 'absolute', right: 0, top: 0,}}
                    disabled={isProcessing}
                >
                    <CloseIcon />
                </IconButton>

                <DialogTitle>Create Sanctions Case</DialogTitle>
                <DialogContent>

                    <Grid container spacing={2}>

                        {
                            !success ?

                                <React.Fragment>

                                    <Grid item  xs={6}>
                                        <FormControl variant="standard" fullWidth>
                                            <InputLabel shrink htmlFor="umr" className={classes.customLabel}>UMR</InputLabel>
                                            <Input
                                                id="umr"
                                                name="umr"
                                                value={umr}
                                                placeholder="Enter UMR"
                                                type="text"
                                                margin="none"
                                                variant="outlined"
                                                autoComplete=""
                                                onChange={e => setUmr(e.target.value)}
                                                autoFocus = {true}
                                                disabled={isProcessing || success}
                                            />
                                        </FormControl>
                                    </Grid>
                                    <Grid item  xs={6}>
                                        <FormControl variant="standard" fullWidth>
                                            <InputLabel shrink htmlFor="broker" className={classes.customLabel}>Broker Code</InputLabel>
                                            <Input
                                                id="broker"
                                                name="broker"
                                                value={broker}
                                                placeholder="Enter broker code"
                                                type="text"
                                                margin="none"
                                                variant="outlined"
                                                autoComplete=""
                                                onChange={e => setBroker(e.target.value)}
                                                autoFocus = {false}
                                                disabled={isProcessing || success}
                                            />
                                        </FormControl>
                                    </Grid>
                                    <Grid item xs={12}>
                                        <FormControl variant="standard" fullWidth>
                                            <InputLabel shrink htmlFor="eventType" className={classes.customLabel}>Event Type</InputLabel>
                                            <Select
                                                variant="standard"
                                                labelId="eventType"
                                                id="eventType"
                                                value={eventType}
                                                onChange={e => setEventType(e.target.value)}>
                                                <MenuItem value={"PREMIUM_WP_CREATE"}>Create Work Package</MenuItem>
                                                <MenuItem value={"PREMIUM_WP_UPDATE"}>Update Work Package</MenuItem>
                                                <MenuItem value={"CLAIM_TRANSACTION_CREATE"}>Create Claim</MenuItem>
                                                <MenuItem value={"CLAIM_TRANSACTION_UPDATE"}>Update Claim</MenuItem>
                                            </Select>
                                        </FormControl>
                                    </Grid>

                                    {(eventType ===  "PREMIUM_WP_CREATE"
                                        || eventType ===  "PREMIUM_WP_UPDATE") &&
                                    <React.Fragment>
                                    <Grid item xs={6}>
                                        <FormControl variant="standard" fullWidth>
                                            <InputLabel shrink htmlFor="application"
                                                        className={classes.customLabel}>Application</InputLabel>
                                            <Select
                                                variant="standard"
                                                labelId="application"
                                                id="application"
                                                value={application}
                                                onChange={e => setApplication(e.target.value)}>
                                                <MenuItem value={"A&S"}>A&S</MenuItem>
                                                <MenuItem value={"EACCOU"}>E Account</MenuItem>
                                            </Select>
                                        </FormControl>
                                    </Grid>
                                        <Grid item  xs={6}>
                                        <FormControl variant="standard" fullWidth>
                                        <InputLabel shrink htmlFor="workOrderReference" className={classes.customLabel}>Work Package Reference</InputLabel>
                                        <Input
                                        id="workOrderReference"
                                        name="workOrderReference"
                                        value={workOrderReference}
                                        placeholder="Enter work order reference"
                                        type="text"
                                        margin="none"
                                        variant="outlined"
                                        autoComplete=""
                                        onChange={e => setWorkOrderReference(e.target.value)}
                                        autoFocus = {false}
                                        disabled={isProcessing || success}
                                        />
                                        </FormControl>
                                        </Grid>
                                        <Grid item xs={6}>
                                        <FormControl variant="standard" fullWidth>
                                        <InputLabel shrink htmlFor="typeOfSubmission" className={classes.customLabel}>Type of Submission</InputLabel>
                                        <Select
                                            variant="standard"
                                            labelId="typeOfSubmission"
                                            id="typeOfSubmission"
                                            value={typeOfSubmission}
                                            onChange={e => setTypeOfSubmission(e.target.value)}>
                                        <MenuItem value={"1"}>First submission</MenuItem>
                                        <MenuItem value={"2"}>Resubmission</MenuItem>
                                        <MenuItem value={"3"}>Correction (after signing)</MenuItem>
                                        <MenuItem value={"4"}>Withdrawal</MenuItem>
                                        <MenuItem value={"5"}>Resubmission following query</MenuItem>
                                        <MenuItem value={"6"}>Submission following rejection</MenuItem>
                                        </Select>
                                        </FormControl>
                                        </Grid>
                                        <Grid item xs={6}>
                                        <FormControl variant="standard" fullWidth>
                                        <InputLabel shrink htmlFor="slipType" className={classes.customLabel}>Slip Type</InputLabel>
                                        <Select
                                            variant="standard"
                                            labelId="slipType"
                                            id="slipType"
                                            value={slipType}
                                            onChange={e => setSlipType(e.target.value)}>
                                        <MenuItem value={"B"}>Binding Authority ( Binders )</MenuItem>
                                        <MenuItem value={"C"}>Cover Lineslip ( Direct - not shown on XIS WF )</MenuItem>
                                        <MenuItem value={"D"}>Direct Insurance (Direct)</MenuItem>
                                        <MenuItem value={"E"}>Excess of Loss Reinsurance ( To be considered as Direct for screening )</MenuItem>
                                        <MenuItem value={"F"}>Facultative Reinsurance ( To be considered as Direct for screening )</MenuItem>
                                        <MenuItem value={"P"}>Proportional Treaty (treaty)</MenuItem>
                                        <MenuItem value={"L"}>Bulking Lineslip ( direct - no shown on XIS WF )</MenuItem>
                                        <MenuItem value={"N"}>Non Bulking Lineslip ( direct - no shown on XIS WF )</MenuItem>
                                        </Select>
                                        </FormControl>
                                        </Grid>
                                        <Grid item xs={6}>
                                        <FormControl variant="standard" fullWidth>
                                        <InputLabel shrink htmlFor="processingRequired" className={classes.customLabel}>Processing Required</InputLabel>
                                        <Select
                                            variant="standard"
                                            labelId="processingRequired"
                                            id="processingRequired"
                                            value={processingRequired}
                                            onChange={e => setProcessingRequired(e.target.value)}>
                                        <MenuItem value={"1"}>Premium Accounting</MenuItem>
                                        <MenuItem value={"2"}>Premium and Policy (S & A) (premium)</MenuItem>
                                        <MenuItem value={"3"}>Policy only</MenuItem>
                                        <MenuItem value={"4"}>Simultaneous Reinstatement ( premium )</MenuItem>
                                        <MenuItem value={"5"}>NPE Review</MenuItem>
                                        </Select>
                                        </FormControl>
                                        </Grid>
                                        <Grid item  xs={6}>
                                        <FormControl variant="standard" fullWidth>
                                        <InputLabel shrink htmlFor="premPresentationDate" className={classes.customLabel}>Presentation Date</InputLabel>
                                            <DatePicker
                                                    onChangeRaw={handleKeypress} //required to prevent typing in field
                                                    onKeyPress={handleKeypress}
                                                    margin="none"
                                                    id="premPresentationDate"
                                                    format="dd/MM/yyyy"
                                                    value = {premPresentationDate}
                                                    onChange = {onPremPresentationDateChange}
                                                    onKeyDown={handleKeyDown}
                                                    KeyboardButtonProps={{'aria-label': 'change date',}}
                                                    disabled = {isProcessing || success}
                                                    autoFocus={false}
                                                    slotProps={{ textField: { variant: 'standard', } }}
                                                    renderInput={props => <TextField
                                                        variant="standard"
                                                        label={"Presentation Date"}
                                                        helperText={props.helperText ? props.helperText : ""} /> }
                                                />
                                        </FormControl>
                                        </Grid>
                                        <Grid item  xs={6}>
                                        <FormControl variant="standard" fullWidth>
                                        <InputLabel shrink htmlFor="premiumFDOLPANS" className={classes.customLabel}>Count of OP and/or FDO</InputLabel>
                                        <Input
                                        id="premiumFDOLPANS"
                                        name="premiumFDOLPANS"
                                        value={premiumFDOLPANS}
                                        placeholder="Enter count of OP and/or FDO"
                                        type="text"
                                        margin="none"
                                        variant="outlined"
                                        autoComplete=""
                                        onChange={e => setPremiumFDOLPANS(e.target.value)}
                                        autoFocus = {false}
                                        disabled={isProcessing || success}
                                        />
                                        </FormControl>
                                        </Grid>
                                        <Grid item  xs={6}>
                                        <FormControl variant="standard" fullWidth>
                                        <InputLabel shrink htmlFor="APRPLPANS" className={classes.customLabel}>Count of A</InputLabel>
                                        <Input
                                        id="APRPLPANS"
                                        name="APRPLPANS"
                                        value={APRPLPANS}
                                        placeholder="Enter count of AP"
                                        type="text"
                                        margin="none"
                                        variant="outlined"
                                        autoComplete=""
                                        onChange={e => setAPRPLPANS(e.target.value)}
                                        autoFocus = {false}
                                        disabled={isProcessing || success}
                                        />
                                        </FormControl>
                                        </Grid>
                                        <Grid item xs={6}>
                                        <FormControl variant="standard" fullWidth>
                                        <InputLabel shrink htmlFor="paidByCheque" className={classes.customLabel}>Paid By Cheque</InputLabel>
                                        <Select
                                            variant="standard"
                                            labelId="paidByCheque"
                                            id="paidByCheque"
                                            value={paidByCheque}
                                            onChange={e => setPaidByCheque(e.target.value)}>
                                        <MenuItem value={"Y"}>Yes</MenuItem>
                                        <MenuItem value={"N"}>No</MenuItem>
                                        </Select>
                                        </FormControl>
                                        </Grid>
                                        <Grid item xs={6}>
                                        <FormControl variant="standard" fullWidth>
                                        <InputLabel shrink htmlFor="premTransactionType" className={classes.customLabel}>Transaction Type</InputLabel>
                                        <Select
                                            variant="standard"
                                            labelId="premTransactionType"
                                            id="premTransactionType"
                                            value={premTransactionType}
                                            onChange={e => setPremTransactionType(e.target.value)}>
                                        <MenuItem value={"FDO"}>FDO</MenuItem>
                                        <MenuItem value={"OP cash"}>OP cash</MenuItem>
                                        <MenuItem value={"AP cash"}>AP cash</MenuItem>
                                        <MenuItem value={"APRP cash"}>APRP cash</MenuItem>
                                        <MenuItem value={"OP non-cash"}>OP non-cash</MenuItem>
                                        <MenuItem value={"AP non-cash"}>AP non-cash</MenuItem>
                                        <MenuItem value={"APRP non-cash"}>APRP non-cash</MenuItem>
                                        </Select>
                                        </FormControl>
                                        </Grid>
                                        <Grid item  xs={6}>
                                        <FormControl variant="standard" fullWidth>
                                        <InputLabel shrink htmlFor="premClassOfBusiness" className={classes.customLabel}>Class Of Business</InputLabel>
                                        <Input
                                        id="premClassOfBusiness"
                                        name="premClassOfBusiness"
                                        value={premClassOfBusiness}
                                        placeholder="Enter class of business"
                                        type="text"
                                        margin="none"
                                        variant="outlined"
                                        autoComplete=""
                                        onChange={e => setPremClassOfBusiness(e.target.value)}
                                        autoFocus = {false}
                                        disabled={isProcessing || success}
                                        />
                                        </FormControl>
                                        </Grid>
                                        <Grid item xs={6}>
                                        <FormControl variant="standard" fullWidth>
                                        <InputLabel shrink htmlFor="premMarket" className={classes.customLabel}>Market</InputLabel>
                                        <Select
                                            variant="standard"
                                            labelId="premMarket"
                                            id="premMarket"
                                            value={premMarket}
                                            onChange={e => setPremMarket(e.target.value)}>
                                        <MenuItem value={"M"}>Mixed Market</MenuItem>
                                        <MenuItem value={"C"}>Company Market</MenuItem>
                                        <MenuItem value={"L"}>Lloyd's</MenuItem>
                                        </Select>
                                        </FormControl>
                                        </Grid>
                                        </React.Fragment>
                                    }

                                    {(eventType === "CLAIM_TRANSACTION_CREATE"
                                        || eventType === "CLAIM_TRANSACTION_UPDATE") &&
                                    <React.Fragment>
                                        <Grid item xs={6}>
                                            <FormControl variant="standard" fullWidth>
                                                <InputLabel shrink htmlFor="ucr"
                                                            className={classes.customLabel}>UCR</InputLabel>
                                                <Input
                                                    id="ucr"
                                                    name="ucr"
                                                    value={ucr}
                                                    placeholder="Enter UCR"
                                                    type="text"
                                                    margin="none"
                                                    variant="outlined"
                                                    autoComplete=""
                                                    onChange={e => setUcr(e.target.value)}
                                                    autoFocus={false}
                                                    disabled={isProcessing || success}
                                                />
                                            </FormControl>
                                        </Grid>
                                        <Grid item xs={6}>
                                            <FormControl variant="standard" fullWidth>
                                                <InputLabel shrink htmlFor="tr"
                                                            className={classes.customLabel}>TR</InputLabel>
                                                <Input
                                                    id="tr"
                                                    name="tr"
                                                    value={tr}
                                                    placeholder="Enter TR"
                                                    type="text"
                                                    margin="none"
                                                    variant="outlined"
                                                    autoComplete=""
                                                    onChange={e => setTr(e.target.value)}
                                                    autoFocus={false}
                                                    disabled={isProcessing || success}
                                                />
                                            </FormControl>
                                        </Grid>
                                        <Grid item xs={6}>
                                            <FormControl variant="standard" fullWidth>
                                                <InputLabel shrink htmlFor="claimTransactionType"
                                                            className={classes.customLabel}>Transaction
                                                    Type</InputLabel>
                                                <Select
                                                    variant="standard"
                                                    labelId="claimTransactionType"
                                                    id="claimTransactionType"
                                                    value={claimTransactionType}
                                                    onChange={e => setClaimTransactionType(e.target.value)}>
                                                    <MenuItem value={"001"}>Advice</MenuItem>
                                                    <MenuItem value={"002"}>Subsequent Advice</MenuItem>
                                                    <MenuItem value={"003"}>Settlement</MenuItem>
                                                    <MenuItem value={"004"}>Subsequent Settlement</MenuItem>
                                                </Select>
                                            </FormControl>
                                        </Grid>
                                        <Grid item  xs={6}>
                                            <FormControl variant="standard" fullWidth>
                                                <InputLabel shrink htmlFor="claimPresentationDate" className={classes.customLabel}>Presentation Date</InputLabel>
                                                <DatePicker
                                                        onChangeRaw={handleKeypress} //required to prevent typing in field
                                                        onKeyPress={handleKeypress}
                                                        margin="none"
                                                        id="claimPresentationDate"
                                                        format="dd/MM/yyyy"
                                                        value = {claimPresentationDate}
                                                        onChange = {onClaimPresentationDateChange}
                                                        onKeyDown={handleKeyDown}
                                                        KeyboardButtonProps={{'aria-label': 'change date',}}
                                                        disabled = {isProcessing || success}
                                                        autoFocus={false}
                                                        slotProps={{ textField: { variant: 'standard', } }}
                                                        renderInput={props => <TextField variant="standard" label={"Presentation Date"} helperText="" /> }
                                                    />
                                            </FormControl>
                                        </Grid>
                                        <Grid item xs={6}>
                                            <FormControl variant="standard" fullWidth>
                                                <InputLabel shrink htmlFor="claimClassOfBusiness"
                                                            className={classes.customLabel}>Class Of
                                                    Business</InputLabel>
                                                <Input
                                                    id="claimClassOfBusiness"
                                                    name="claimClassOfBusiness"
                                                    value={claimClassOfBusiness}
                                                    placeholder="Enter class of business"
                                                    type="text"
                                                    margin="none"
                                                    variant="outlined"
                                                    autoComplete=""
                                                    onChange={e => setClaimClassOfBusiness(e.target.value)}
                                                    autoFocus={false}
                                                    disabled={isProcessing || success}
                                                />
                                            </FormControl>
                                        </Grid>
                                        <Grid item xs={6}>
                                            <FormControl variant="standard" fullWidth>
                                                <InputLabel shrink htmlFor="claimMarket"
                                                            className={classes.customLabel}>Market</InputLabel>
                                                <Select
                                                    variant="standard"
                                                    labelId="claimMarket"
                                                    id="claimMarket"
                                                    value={claimMarket}
                                                    onChange={e => setClaimMarket(e.target.value)}>
                                                    <MenuItem value={"M"}>Mixed Market</MenuItem>
                                                    <MenuItem value={"C"}>Company Market</MenuItem>
                                                    <MenuItem value={"L"}>Lloyd's</MenuItem>
                                                </Select>
                                            </FormControl>
                                        </Grid>
                                    </React.Fragment>
                                    }
                                    <React.Fragment>
                                        <Grid item xs={12}>
                                            <Button onClick={() => handleAddDoc()}
                                                    variant="contained"
                                                    color={"secondary"}
                                                    disabled={isProcessing || success}
                                                    style={{marginRight: '8px'}}
                                            >
                                                Add
                                            </Button>
                                            <Button
                                                onClick={() => handleRemoveDoc()}
                                                variant="contained"
                                                color={"secondary"}
                                                disabled={isProcessing || success}>
                                                Remove
                                            </Button>
                                        </Grid>
                                    </React.Fragment>

                                    { docCount >= 1 &&
                                    <React.Fragment>
                                        <Grid item xs={6}>
                                            <FormControl variant="standard" fullWidth>
                                                <InputLabel shrink htmlFor="type1"
                                                            className={classes.customLabel}>Type</InputLabel>
                                                <Input
                                                    id="type1"
                                                    name="type1"
                                                    value={type1}
                                                    placeholder="Enter type"
                                                    type="text"
                                                    margin="none"
                                                    variant="outlined"
                                                    autoComplete=""
                                                    onChange={e => setType1(e.target.value)}
                                                    autoFocus={false}
                                                    disabled={isProcessing || success}
                                                />
                                            </FormControl>
                                        </Grid>
                                        <Grid item xs={6}>
                                            <FormControl variant="standard" fullWidth>
                                                <InputLabel shrink htmlFor="documentId1" className={classes.customLabel}>Document
                                                    Id</InputLabel>
                                                <Input
                                                    id="documentId1"
                                                    name="documentId1"
                                                    value={documentId1}
                                                    placeholder="Enter document Id"
                                                    type="text"
                                                    margin="none"
                                                    variant="outlined"
                                                    autoComplete=""
                                                    onChange={e => setDocumentId1(e.target.value)}
                                                    autoFocus={false}
                                                    disabled={isProcessing || success}
                                                />
                                            </FormControl>
                                        </Grid>
                                        <Grid item xs={6}>
                                            <FormControl variant="standard" fullWidth>
                                                <InputLabel shrink htmlFor="documentReference1"
                                                            className={classes.customLabel}>Document
                                                    Reference</InputLabel>
                                                <Input
                                                    id="documentReference1"
                                                    name="documentReference1"
                                                    value={documentReference1}
                                                    placeholder="Enter Document Reference"
                                                    type="text"
                                                    margin="none"
                                                    variant="outlined"
                                                    autoComplete=""
                                                    onChange={e => setDocumentReference1(e.target.value)}
                                                    autoFocus={false}
                                                    disabled={isProcessing || success}
                                                />
                                            </FormControl>
                                        </Grid>
                                        <Grid item xs={6}>
                                            <FormControl variant="standard" fullWidth>
                                                <InputLabel shrink htmlFor="documentVersion1"
                                                            className={classes.customLabel}>Document
                                                    Version</InputLabel>
                                                <Input
                                                    id="documentVersion1"
                                                    name="documentVersion1"
                                                    value={documentVersion1}
                                                    placeholder="Enter Document Version"
                                                    type="text"
                                                    margin="none"
                                                    variant="outlined"
                                                    autoComplete=""
                                                    onChange={e => setDocumentVersion1(e.target.value)}
                                                    autoFocus={false}
                                                    disabled={isProcessing || success}
                                                />
                                            </FormControl>
                                        </Grid>
                                    </React.Fragment>
                                    }
                                    { docCount >= 2 &&
                                    <React.Fragment>
                                        <Grid item xs={6}>
                                            <FormControl variant="standard" fullWidth>
                                                <InputLabel shrink htmlFor="type2"
                                                            className={classes.customLabel}>Type</InputLabel>
                                                <Input
                                                    id="type2"
                                                    name="type2"
                                                    value={type2}
                                                    placeholder="Enter type"
                                                    type="text"
                                                    margin="none"
                                                    variant="outlined"
                                                    autoComplete=""
                                                    onChange={e => setType2(e.target.value)}
                                                    autoFocus={false}
                                                    disabled={isProcessing || success}
                                                />
                                            </FormControl>
                                        </Grid>
                                        <Grid item xs={6}>
                                            <FormControl variant="standard" fullWidth>
                                                <InputLabel shrink htmlFor="documentId2" className={classes.customLabel}>Document
                                                    Id</InputLabel>
                                                <Input
                                                    id="documentId2"
                                                    name="documentId2"
                                                    value={documentId2}
                                                    placeholder="Enter document Id"
                                                    type="text"
                                                    margin="none"
                                                    variant="outlined"
                                                    autoComplete=""
                                                    onChange={e => setDocumentId2(e.target.value)}
                                                    autoFocus={false}
                                                    disabled={isProcessing || success}
                                                />
                                            </FormControl>
                                        </Grid>
                                        <Grid item xs={6}>
                                            <FormControl variant="standard" fullWidth>
                                                <InputLabel shrink htmlFor="documentReference2"
                                                            className={classes.customLabel}>Document
                                                    Reference</InputLabel>
                                                <Input
                                                    id="documentReference2"
                                                    name="documentReference2"
                                                    value={documentReference2}
                                                    placeholder="Enter Document Reference"
                                                    type="text"
                                                    margin="none"
                                                    variant="outlined"
                                                    autoComplete=""
                                                    onChange={e => setDocumentReference2(e.target.value)}
                                                    autoFocus={false}
                                                    disabled={isProcessing || success}
                                                />
                                            </FormControl>
                                        </Grid>
                                        <Grid item xs={6}>
                                            <FormControl variant="standard" fullWidth>
                                                <InputLabel shrink htmlFor="documentVersion2"
                                                            className={classes.customLabel}>Document
                                                    Version</InputLabel>
                                                <Input
                                                    id="documentVersion2"
                                                    name="documentVersion2"
                                                    value={documentVersion2}
                                                    placeholder="Enter Document Version"
                                                    type="text"
                                                    margin="none"
                                                    variant="outlined"
                                                    autoComplete=""
                                                    onChange={e => setDocumentVersion2(e.target.value)}
                                                    autoFocus={false}
                                                    disabled={isProcessing || success}
                                                />
                                            </FormControl>
                                        </Grid>
                                    </React.Fragment>
                                    }
                                    { docCount >= 3 &&
                                    <React.Fragment>
                                        <Grid item xs={6}>
                                            <FormControl variant="standard" fullWidth>
                                                <InputLabel shrink htmlFor="type3"
                                                            className={classes.customLabel}>Type</InputLabel>
                                                <Input
                                                    id="type3"
                                                    name="type3"
                                                    value={type3}
                                                    placeholder="Enter type"
                                                    type="text"
                                                    margin="none"
                                                    variant="outlined"
                                                    autoComplete=""
                                                    onChange={e => setType3(e.target.value)}
                                                    autoFocus={false}
                                                    disabled={isProcessing || success}
                                                />
                                            </FormControl>
                                        </Grid>
                                        <Grid item xs={6}>
                                            <FormControl variant="standard" fullWidth>
                                                <InputLabel shrink htmlFor="documentId3" className={classes.customLabel}>Document
                                                    Id</InputLabel>
                                                <Input
                                                    id="documentId3"
                                                    name="documentId3"
                                                    value={documentId3}
                                                    placeholder="Enter document Id"
                                                    type="text"
                                                    margin="none"
                                                    variant="outlined"
                                                    autoComplete=""
                                                    onChange={e => setDocumentId3(e.target.value)}
                                                    autoFocus={false}
                                                    disabled={isProcessing || success}
                                                />
                                            </FormControl>
                                        </Grid>
                                        <Grid item xs={6}>
                                            <FormControl variant="standard" fullWidth>
                                                <InputLabel shrink htmlFor="documentReference3"
                                                            className={classes.customLabel}>Document
                                                    Reference</InputLabel>
                                                <Input
                                                    id="documentReference3"
                                                    name="documentReference3"
                                                    value={documentReference3}
                                                    placeholder="Enter Document Reference"
                                                    type="text"
                                                    margin="none"
                                                    variant="outlined"
                                                    autoComplete=""
                                                    onChange={e => setDocumentReference3(e.target.value)}
                                                    autoFocus={false}
                                                    disabled={isProcessing || success}
                                                />
                                            </FormControl>
                                        </Grid>
                                        <Grid item xs={6}>
                                            <FormControl variant="standard" fullWidth>
                                                <InputLabel shrink htmlFor="documentVersion3"
                                                            className={classes.customLabel}>Document
                                                    Version</InputLabel>
                                                <Input
                                                    id="documentVersion3"
                                                    name="documentVersion3"
                                                    value={documentVersion3}
                                                    placeholder="Enter Document Version"
                                                    type="text"
                                                    margin="none"
                                                    variant="outlined"
                                                    autoComplete=""
                                                    onChange={e => setDocumentVersion3(e.target.value)}
                                                    autoFocus={false}
                                                    disabled={isProcessing || success}
                                                />
                                            </FormControl>
                                        </Grid>
                                    </React.Fragment>
                                    }
                                    { docCount >= 4 &&
                                    <React.Fragment>
                                        <Grid item xs={6}>
                                            <FormControl variant="standard" fullWidth>
                                                <InputLabel shrink htmlFor="type4"
                                                            className={classes.customLabel}>Type</InputLabel>
                                                <Input
                                                    id="type4"
                                                    name="type4"
                                                    value={type4}
                                                    placeholder="Enter type"
                                                    type="text"
                                                    margin="none"
                                                    variant="outlined"
                                                    autoComplete=""
                                                    onChange={e => setType4(e.target.value)}
                                                    autoFocus={false}
                                                    disabled={isProcessing || success}
                                                />
                                            </FormControl>
                                        </Grid>
                                        <Grid item xs={6}>
                                            <FormControl variant="standard" fullWidth>
                                                <InputLabel shrink htmlFor="documentId4" className={classes.customLabel}>Document
                                                    Id</InputLabel>
                                                <Input
                                                    id="documentId4"
                                                    name="documentId4"
                                                    value={documentId4}
                                                    placeholder="Enter document Id"
                                                    type="text"
                                                    margin="none"
                                                    variant="outlined"
                                                    autoComplete=""
                                                    onChange={e => setDocumentId4(e.target.value)}
                                                    autoFocus={false}
                                                    disabled={isProcessing || success}
                                                />
                                            </FormControl>
                                        </Grid>
                                        <Grid item xs={6}>
                                            <FormControl variant="standard" fullWidth>
                                                <InputLabel shrink htmlFor="documentReference4"
                                                            className={classes.customLabel}>Document
                                                    Reference</InputLabel>
                                                <Input
                                                    id="documentReference4"
                                                    name="documentReference4"
                                                    value={documentReference4}
                                                    placeholder="Enter Document Reference"
                                                    type="text"
                                                    margin="none"
                                                    variant="outlined"
                                                    autoComplete=""
                                                    onChange={e => setDocumentReference4(e.target.value)}
                                                    autoFocus={false}
                                                    disabled={isProcessing || success}
                                                />
                                            </FormControl>
                                        </Grid>
                                        <Grid item xs={6}>
                                            <FormControl variant="standard" fullWidth>
                                                <InputLabel shrink htmlFor="documentVersion4"
                                                            className={classes.customLabel}>Document
                                                    Version</InputLabel>
                                                <Input
                                                    id="documentVersion4"
                                                    name="documentVersion4"
                                                    value={documentVersion4}
                                                    placeholder="Enter Document Version"
                                                    type="text"
                                                    margin="none"
                                                    variant="outlined"
                                                    autoComplete=""
                                                    onChange={e => setDocumentVersion4(e.target.value)}
                                                    autoFocus={false}
                                                    disabled={isProcessing || success}
                                                />
                                            </FormControl>
                                        </Grid>
                                    </React.Fragment>
                                    }
                                    { docCount >= 5 &&
                                    <React.Fragment>
                                        <Grid item xs={6}>
                                            <FormControl variant="standard" fullWidth>
                                                <InputLabel shrink htmlFor="type5"
                                                            className={classes.customLabel}>Type</InputLabel>
                                                <Input
                                                    id="type5"
                                                    name="type5"
                                                    value={type5}
                                                    placeholder="Enter type"
                                                    type="text"
                                                    margin="none"
                                                    variant="outlined"
                                                    autoComplete=""
                                                    onChange={e => setType5(e.target.value)}
                                                    autoFocus={false}
                                                    disabled={isProcessing || success}
                                                />
                                            </FormControl>
                                        </Grid>
                                        <Grid item xs={6}>
                                            <FormControl variant="standard" fullWidth>
                                                <InputLabel shrink htmlFor="documentId5" className={classes.customLabel}>Document
                                                    Id</InputLabel>
                                                <Input
                                                    id="documentId5"
                                                    name="documentId5"
                                                    value={documentId5}
                                                    placeholder="Enter document Id"
                                                    type="text"
                                                    margin="none"
                                                    variant="outlined"
                                                    autoComplete=""
                                                    onChange={e => setDocumentId5(e.target.value)}
                                                    autoFocus={false}
                                                    disabled={isProcessing || success}
                                                />
                                            </FormControl>
                                        </Grid>
                                        <Grid item xs={6}>
                                            <FormControl variant="standard" fullWidth>
                                                <InputLabel shrink htmlFor="documentReference5"
                                                            className={classes.customLabel}>Document
                                                    Reference</InputLabel>
                                                <Input
                                                    id="documentReference5"
                                                    name="documentReference5"
                                                    value={documentReference5}
                                                    placeholder="Enter Document Reference"
                                                    type="text"
                                                    margin="none"
                                                    variant="outlined"
                                                    autoComplete=""
                                                    onChange={e => setDocumentReference5(e.target.value)}
                                                    autoFocus={false}
                                                    disabled={isProcessing || success}
                                                />
                                            </FormControl>
                                        </Grid>
                                        <Grid item xs={6}>
                                            <FormControl variant="standard" fullWidth>
                                                <InputLabel shrink htmlFor="documentVersion5"
                                                            className={classes.customLabel}>Document
                                                    Version</InputLabel>
                                                <Input
                                                    id="documentVersion5"
                                                    name="documentVersion5"
                                                    value={documentVersion5}
                                                    placeholder="Enter Document Version"
                                                    type="text"
                                                    margin="none"
                                                    variant="outlined"
                                                    autoComplete=""
                                                    onChange={e => setDocumentVersion5(e.target.value)}
                                                    autoFocus={false}
                                                    disabled={isProcessing || success}
                                                />
                                            </FormControl>
                                        </Grid>
                                    </React.Fragment>
                                    }
                                    { docCount >= 6 &&
                                    <React.Fragment>
                                        <Grid item xs={6}>
                                            <FormControl variant="standard" fullWidth>
                                                <InputLabel shrink htmlFor="type6"
                                                            className={classes.customLabel}>Type</InputLabel>
                                                <Input
                                                    id="type6"
                                                    name="type6"
                                                    value={type6}
                                                    placeholder="Enter type"
                                                    type="text"
                                                    margin="none"
                                                    variant="outlined"
                                                    autoComplete=""
                                                    onChange={e => setType6(e.target.value)}
                                                    autoFocus={false}
                                                    disabled={isProcessing || success}
                                                />
                                            </FormControl>
                                        </Grid>
                                        <Grid item xs={6}>
                                            <FormControl variant="standard" fullWidth>
                                                <InputLabel shrink htmlFor="documentId6" className={classes.customLabel}>Document
                                                    Id</InputLabel>
                                                <Input
                                                    id="documentId6"
                                                    name="documentId6"
                                                    value={documentId6}
                                                    placeholder="Enter document Id"
                                                    type="text"
                                                    margin="none"
                                                    variant="outlined"
                                                    autoComplete=""
                                                    onChange={e => setDocumentId6(e.target.value)}
                                                    autoFocus={false}
                                                    disabled={isProcessing || success}
                                                />
                                            </FormControl>
                                        </Grid>
                                        <Grid item xs={6}>
                                            <FormControl variant="standard" fullWidth>
                                                <InputLabel shrink htmlFor="documentReference6"
                                                            className={classes.customLabel}>Document
                                                    Reference</InputLabel>
                                                <Input
                                                    id="documentReference6"
                                                    name="documentReference6"
                                                    value={documentReference6}
                                                    placeholder="Enter Document Reference"
                                                    type="text"
                                                    margin="none"
                                                    variant="outlined"
                                                    autoComplete=""
                                                    onChange={e => setDocumentReference6(e.target.value)}
                                                    autoFocus={false}
                                                    disabled={isProcessing || success}
                                                />
                                            </FormControl>
                                        </Grid>
                                        <Grid item xs={6}>
                                            <FormControl variant="standard" fullWidth>
                                                <InputLabel shrink htmlFor="documentVersion6"
                                                            className={classes.customLabel}>Document
                                                    Version</InputLabel>
                                                <Input
                                                    id="documentVersion6"
                                                    name="documentVersion6"
                                                    value={documentVersion6}
                                                    placeholder="Enter Document Version"
                                                    type="text"
                                                    margin="none"
                                                    variant="outlined"
                                                    autoComplete=""
                                                    onChange={e => setDocumentVersion6(e.target.value)}
                                                    autoFocus={false}
                                                    disabled={isProcessing || success}
                                                />
                                            </FormControl>
                                        </Grid>
                                    </React.Fragment>
                                    }
                                    { docCount >= 7 &&
                                    <React.Fragment>
                                        <Grid item xs={6}>
                                            <FormControl variant="standard" fullWidth>
                                                <InputLabel shrink htmlFor="type7"
                                                            className={classes.customLabel}>Type</InputLabel>
                                                <Input
                                                    id="type7"
                                                    name="type7"
                                                    value={type7}
                                                    placeholder="Enter type"
                                                    type="text"
                                                    margin="none"
                                                    variant="outlined"
                                                    autoComplete=""
                                                    onChange={e => setType7(e.target.value)}
                                                    autoFocus={false}
                                                    disabled={isProcessing || success}
                                                />
                                            </FormControl>
                                        </Grid>
                                        <Grid item xs={6}>
                                            <FormControl variant="standard" fullWidth>
                                                <InputLabel shrink htmlFor="documentId7" className={classes.customLabel}>Document
                                                    Id</InputLabel>
                                                <Input
                                                    id="documentId7"
                                                    name="documentId7"
                                                    value={documentId7}
                                                    placeholder="Enter document Id"
                                                    type="text"
                                                    margin="none"
                                                    variant="outlined"
                                                    autoComplete=""
                                                    onChange={e => setDocumentId7(e.target.value)}
                                                    autoFocus={false}
                                                    disabled={isProcessing || success}
                                                />
                                            </FormControl>
                                        </Grid>
                                        <Grid item xs={6}>
                                            <FormControl variant="standard" fullWidth>
                                                <InputLabel shrink htmlFor="documentReference7"
                                                            className={classes.customLabel}>Document
                                                    Reference</InputLabel>
                                                <Input
                                                    id="documentReference7"
                                                    name="documentReference7"
                                                    value={documentReference7}
                                                    placeholder="Enter Document Reference"
                                                    type="text"
                                                    margin="none"
                                                    variant="outlined"
                                                    autoComplete=""
                                                    onChange={e => setDocumentReference7(e.target.value)}
                                                    autoFocus={false}
                                                    disabled={isProcessing || success}
                                                />
                                            </FormControl>
                                        </Grid>
                                        <Grid item xs={6}>
                                            <FormControl variant="standard" fullWidth>
                                                <InputLabel shrink htmlFor="documentVersion7"
                                                            className={classes.customLabel}>Document
                                                    Version</InputLabel>
                                                <Input
                                                    id="documentVersion7"
                                                    name="documentVersion7"
                                                    value={documentVersion7}
                                                    placeholder="Enter Document Version"
                                                    type="text"
                                                    margin="none"
                                                    variant="outlined"
                                                    autoComplete=""
                                                    onChange={e => setDocumentVersion7(e.target.value)}
                                                    autoFocus={false}
                                                    disabled={isProcessing || success}
                                                />
                                            </FormControl>
                                        </Grid>
                                    </React.Fragment>
                                    }
                                    { docCount >= 8 &&
                                    <React.Fragment>
                                        <Grid item xs={6}>
                                            <FormControl variant="standard" fullWidth>
                                                <InputLabel shrink htmlFor="type8"
                                                            className={classes.customLabel}>Type</InputLabel>
                                                <Input
                                                    id="type8"
                                                    name="type8"
                                                    value={type8}
                                                    placeholder="Enter type"
                                                    type="text"
                                                    margin="none"
                                                    variant="outlined"
                                                    autoComplete=""
                                                    onChange={e => setType8(e.target.value)}
                                                    autoFocus={false}
                                                    disabled={isProcessing || success}
                                                />
                                            </FormControl>
                                        </Grid>
                                        <Grid item xs={6}>
                                            <FormControl variant="standard" fullWidth>
                                                <InputLabel shrink htmlFor="documentId8" className={classes.customLabel}>Document
                                                    Id</InputLabel>
                                                <Input
                                                    id="documentId8"
                                                    name="documentId8"
                                                    value={documentId8}
                                                    placeholder="Enter document Id"
                                                    type="text"
                                                    margin="none"
                                                    variant="outlined"
                                                    autoComplete=""
                                                    onChange={e => setDocumentId8(e.target.value)}
                                                    autoFocus={false}
                                                    disabled={isProcessing || success}
                                                />
                                            </FormControl>
                                        </Grid>
                                        <Grid item xs={6}>
                                            <FormControl variant="standard" fullWidth>
                                                <InputLabel shrink htmlFor="documentReference8"
                                                            className={classes.customLabel}>Document
                                                    Reference</InputLabel>
                                                <Input
                                                    id="documentReference8"
                                                    name="documentReference8"
                                                    value={documentReference8}
                                                    placeholder="Enter Document Reference"
                                                    type="text"
                                                    margin="none"
                                                    variant="outlined"
                                                    autoComplete=""
                                                    onChange={e => setDocumentReference8(e.target.value)}
                                                    autoFocus={false}
                                                    disabled={isProcessing || success}
                                                />
                                            </FormControl>
                                        </Grid>
                                        <Grid item xs={6}>
                                            <FormControl variant="standard" fullWidth>
                                                <InputLabel shrink htmlFor="documentVersion8"
                                                            className={classes.customLabel}>Document
                                                    Version</InputLabel>
                                                <Input
                                                    id="documentVersion8"
                                                    name="documentVersion8"
                                                    value={documentVersion8}
                                                    placeholder="Enter Document Version"
                                                    type="text"
                                                    margin="none"
                                                    variant="outlined"
                                                    autoComplete=""
                                                    onChange={e => setDocumentVersion8(e.target.value)}
                                                    autoFocus={false}
                                                    disabled={isProcessing || success}
                                                />
                                            </FormControl>
                                        </Grid>
                                    </React.Fragment>
                                    }
                                    { docCount >= 9 &&
                                    <React.Fragment>
                                        <Grid item xs={6}>
                                            <FormControl variant="standard" fullWidth>
                                                <InputLabel shrink htmlFor="type9"
                                                            className={classes.customLabel}>Type</InputLabel>
                                                <Input
                                                    id="type9"
                                                    name="type9"
                                                    value={type9}
                                                    placeholder="Enter type"
                                                    type="text"
                                                    margin="none"
                                                    variant="outlined"
                                                    autoComplete=""
                                                    onChange={e => setType9(e.target.value)}
                                                    autoFocus={false}
                                                    disabled={isProcessing || success}
                                                />
                                            </FormControl>
                                        </Grid>
                                        <Grid item xs={6}>
                                            <FormControl variant="standard" fullWidth>
                                                <InputLabel shrink htmlFor="documentId9" className={classes.customLabel}>Document
                                                    Id</InputLabel>
                                                <Input
                                                    id="documentId9"
                                                    name="documentId9"
                                                    value={documentId9}
                                                    placeholder="Enter document Id"
                                                    type="text"
                                                    margin="none"
                                                    variant="outlined"
                                                    autoComplete=""
                                                    onChange={e => setDocumentId9(e.target.value)}
                                                    autoFocus={false}
                                                    disabled={isProcessing || success}
                                                />
                                            </FormControl>
                                        </Grid>
                                        <Grid item xs={6}>
                                            <FormControl variant="standard" fullWidth>
                                                <InputLabel shrink htmlFor="documentReference9"
                                                            className={classes.customLabel}>Document
                                                    Reference</InputLabel>
                                                <Input
                                                    id="documentReference9"
                                                    name="documentReference9"
                                                    value={documentReference9}
                                                    placeholder="Enter Document Reference"
                                                    type="text"
                                                    margin="none"
                                                    variant="outlined"
                                                    autoComplete=""
                                                    onChange={e => setDocumentReference9(e.target.value)}
                                                    autoFocus={false}
                                                    disabled={isProcessing || success}
                                                />
                                            </FormControl>
                                        </Grid>
                                        <Grid item xs={6}>
                                            <FormControl variant="standard" fullWidth>
                                                <InputLabel shrink htmlFor="documentVersion9"
                                                            className={classes.customLabel}>Document
                                                    Version</InputLabel>
                                                <Input
                                                    id="documentVersion9"
                                                    name="documentVersion9"
                                                    value={documentVersion9}
                                                    placeholder="Enter Document Version"
                                                    type="text"
                                                    margin="none"
                                                    variant="outlined"
                                                    autoComplete=""
                                                    onChange={e => setDocumentVersion9(e.target.value)}
                                                    autoFocus={false}
                                                    disabled={isProcessing || success}
                                                />
                                            </FormControl>
                                        </Grid>
                                    </React.Fragment>
                                    }
                                    { docCount >= 10 &&
                                    <React.Fragment>
                                        <Grid item xs={6}>
                                            <FormControl variant="standard" fullWidth>
                                                <InputLabel shrink htmlFor="type10"
                                                            className={classes.customLabel}>Type</InputLabel>
                                                <Input
                                                    id="type10"
                                                    name="type10"
                                                    value={type10}
                                                    placeholder="Enter type"
                                                    type="text"
                                                    margin="none"
                                                    variant="outlined"
                                                    autoComplete=""
                                                    onChange={e => setType10(e.target.value)}
                                                    autoFocus={false}
                                                    disabled={isProcessing || success}
                                                />
                                            </FormControl>
                                        </Grid>
                                        <Grid item xs={6}>
                                            <FormControl variant="standard" fullWidth>
                                                <InputLabel shrink htmlFor="documentId10" className={classes.customLabel}>Document
                                                    Id</InputLabel>
                                                <Input
                                                    id="documentId10"
                                                    name="documentId10"
                                                    value={documentId10}
                                                    placeholder="Enter document Id"
                                                    type="text"
                                                    margin="none"
                                                    variant="outlined"
                                                    autoComplete=""
                                                    onChange={e => setDocumentId10(e.target.value)}
                                                    autoFocus={false}
                                                    disabled={isProcessing || success}
                                                />
                                            </FormControl>
                                        </Grid>
                                        <Grid item xs={6}>
                                            <FormControl variant="standard" fullWidth>
                                                <InputLabel shrink htmlFor="documentReference10"
                                                            className={classes.customLabel}>Document
                                                    Reference</InputLabel>
                                                <Input
                                                    id="documentReference10"
                                                    name="documentReference10"
                                                    value={documentReference10}
                                                    placeholder="Enter Document Reference"
                                                    type="text"
                                                    margin="none"
                                                    variant="outlined"
                                                    autoComplete=""
                                                    onChange={e => setDocumentReference10(e.target.value)}
                                                    autoFocus={false}
                                                    disabled={isProcessing || success}
                                                />
                                            </FormControl>
                                        </Grid>
                                        <Grid item xs={6}>
                                            <FormControl variant="standard" fullWidth>
                                                <InputLabel shrink htmlFor="documentVersion10"
                                                            className={classes.customLabel}>Document
                                                    Version</InputLabel>
                                                <Input
                                                    id="documentVersion10"
                                                    name="documentVersion10"
                                                    value={documentVersion10}
                                                    placeholder="Enter Document Version"
                                                    type="text"
                                                    margin="none"
                                                    variant="outlined"
                                                    autoComplete=""
                                                    onChange={e => setDocumentVersion10(e.target.value)}
                                                    autoFocus={false}
                                                    disabled={isProcessing || success}
                                                />
                                            </FormControl>
                                        </Grid>
                                    </React.Fragment>
                                    }
                                </React.Fragment> :

                                <Grid item  xs={12}>

                                    <Summary/>

                                    <DialogContentText sx={(theme)=>({Bottom: theme.spacing(2)})}>Case Submission Complete</DialogContentText>

                                </Grid>

                        }
                    </Grid>

                </DialogContent>

                <DialogActions>
                    {
                        !isProcessing &&
                        <Button onClick={() => handleCloseDialog()} variant="contained" color={"grey"} >
                            { success ? "Close"  : "Cancel"}
                        </Button>
                    }
                    {
                        !success &&
                        <Button onClick={() => handleSubmitClick()} variant={"contained"} color="secondary">
                            {isProcessing ? "Processing..." : "Submit"}
                        </Button>
                    }

                </DialogActions>
                {
                    isProcessing &&
                    <LinearProgress variant={"indeterminate"} color={"primary"}/>
                }
            </Dialog>
        </div>
    );
}

SanctionsCreateCaseDialog.propTypes = {
    actionConfig: PropTypes.object.isRequired,
    classes: PropTypes.object.isRequired,
    handleCloseDialog: PropTypes.func.isRequired,
    triggerRefreshAuthToken: PropTypes.func.isRequired,
    userDetails: PropTypes.object.isRequired
};


export default (withStyles(styles, { withTheme: true })(SanctionsCreateCaseDialog));